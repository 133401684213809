import { useRouteMatch } from 'react-router';

import { Aging } from 'pages/Private/Reports/Aging';
import { Aging4hrs } from 'pages/Private/Reports/Aging4hrs';
import { AgingFG } from 'pages/Private/Reports/AgingFG';
import { AgingPllWithParts } from 'pages/Private/Reports/AgingPllWithParts';
import { AgingWithParts } from 'pages/Private/Reports/AgingWithParts';
import { Billing } from 'pages/Private/Reports/Billing';
import { BorWithoutPN } from 'pages/Private/Reports/BORWithoutPN';
import { CancellationChargebacks } from 'pages/Private/Reports/CancellationChargebacks';
import { CancellationQA } from 'pages/Private/Reports/CancellationQA';
import { CheckinProduction } from 'pages/Private/Reports/CheckinProduction';
import { ComponentRegistration } from 'pages/Private/Reports/ComponentRegistration';
import { DeliveryConfirmation } from 'pages/Private/Reports/DeliveryConfirmation';
import { FgConsumedParts } from 'pages/Private/Reports/FgConsumedParts';
import { GeneralBudgets } from 'pages/Private/Reports/GeneralBudgets';
import { HistoricStockPosition } from 'pages/Private/Reports/HistoricStockPosition';
import { ImeiQuery } from 'pages/Private/Reports/ImeiQuery';
import { InOutPll } from 'pages/Private/Reports/InOutPll';
import { InOutReport } from 'pages/Private/Reports/InOutReport';
import { NonCompliance } from 'pages/Private/Reports/NonCompliance';
import { ObjectsReceived } from 'pages/Private/Reports/ObjectsReceived';
import { OpenServiceOrderReport } from 'pages/Private/Reports/OpenServiceOrderReport';
import { OsAssignment } from 'pages/Private/Reports/OsAssignment';
import { OsOpeningErrors } from 'pages/Private/Reports/OsOpeningErrors';
import { OsReported } from 'pages/Private/Reports/OsReported';
import { OutOfWarranty } from 'pages/Private/Reports/OutOfWarranty';
import { Parts } from 'pages/Private/Reports/Parts';
import { PartsBPO } from 'pages/Private/Reports/PartsBPO';
import { PartsBPOTotal } from 'pages/Private/Reports/PartsBPOTotal';
import { PartsConsumption } from 'pages/Private/Reports/PartsConsumption';
import { PartsPLL } from 'pages/Private/Reports/PartsPLL';
import { PartsXModelsBOR } from 'pages/Private/Reports/PartsXModelsBOR';
import { PnQueryArchived } from 'pages/Private/Reports/PnQueryArchived';
import { PositionEntryNotes } from 'pages/Private/Reports/PositionEntryNotes';
import { ProductionBySector } from 'pages/Private/Reports/ProductionBySector';
import { ProductionByTechnician } from 'pages/Private/Reports/ProductionByTechnician';
import { QualityControl } from 'pages/Private/Reports/QualityControl';
import { QueryVoc } from 'pages/Private/Reports/QueryVoc';
import { ReceivingParts } from 'pages/Private/Reports/ReceivingParts';
import { ResubmissionCasesOpen } from 'pages/Private/Reports/ResubmissionCasesOpen';
import { ResubmissionCasesUpdate } from 'pages/Private/Reports/ResubmissionCasesUpdated';
import { SamsungBudgetReport } from 'pages/Private/Reports/SamsungBudgetReport';
import { StockPosition } from 'pages/Private/Reports/StockPosition';
import { SummaryCMP } from 'pages/Private/Reports/SummaryCMP';
import { TechnicalStock } from 'pages/Private/Reports/TechnicalStock';
import { UnlockHistory } from 'pages/Private/Reports/UnlockHistory';

import { Route } from '../Route';

export const ReportRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/open-service-order`} component={OpenServiceOrderReport} />
      <Route isPrivate path={`${url}/production-by-sector`} component={ProductionBySector} />
      <Route isPrivate path={`${url}/objects-received`} component={ObjectsReceived} />
      <Route isPrivate path={`${url}/checkin-production`} component={CheckinProduction} />
      <Route
        isPrivate
        path={`${url}/production-by-technician`}
        component={ProductionByTechnician}
      />
      <Route isPrivate path={`${url}/quality-control`} component={QualityControl} />
      <Route isPrivate path={`${url}/os-opening-errors`} component={OsOpeningErrors} />
      <Route isPrivate path={`${url}/aging-with-parts`} component={AgingWithParts} />
      <Route isPrivate path={`${url}/general-budgets`} component={GeneralBudgets} />
      <Route isPrivate path={`${url}/in-out`} component={InOutReport} />
      <Route isPrivate path={`${url}/aging-fg`} component={AgingFG} />
      <Route isPrivate path={`${url}/aging`} component={Aging} />
      <Route isPrivate path={`${url}/samsung-budget-report`} component={SamsungBudgetReport} />
      <Route isPrivate path={`${url}/resubmission-cases-open`} component={ResubmissionCasesOpen} />
      <Route
        isPrivate
        path={`${url}/resubmission-cases-updated`}
        component={ResubmissionCasesUpdate}
      />
      <Route isPrivate path={`${url}/aging-4hrs`} component={Aging4hrs} />
      <Route isPrivate path={`${url}/os-assignment`} component={OsAssignment} />
      <Route isPrivate path={`${url}/billing`} component={Billing} />
      <Route isPrivate path={`${url}/parts-x-models`} component={PartsXModelsBOR} />
      <Route isPrivate path={`${url}/component-registration`} component={ComponentRegistration} />
      <Route isPrivate path={`${url}/out-of-warranty`} component={OutOfWarranty} />
      <Route isPrivate path={`${url}/parts`} component={Parts} />
      <Route isPrivate path={`${url}/parts-bpo`} component={PartsBPO} />
      <Route isPrivate path={`${url}/parts-bpo-total`} component={PartsBPOTotal} />
      <Route
        isPrivate
        path={`${url}/cancellation-of-chargebacks`}
        component={CancellationChargebacks}
      />
      <Route isPrivate path={`${url}/cancellation-QA`} component={CancellationQA} />
      <Route isPrivate path={`${url}/pn-query-archived`} component={PnQueryArchived} />
      <Route isPrivate path={`${url}/query-voc`} component={QueryVoc} />
      <Route isPrivate path={`${url}/parts-consumption`} component={PartsConsumption} />
      <Route isPrivate path={`${url}/os-reported`} component={OsReported} />
      <Route isPrivate path={`${url}/bor-without-pn`} component={BorWithoutPN} />
      <Route isPrivate path={`${url}/technical-stock`} component={TechnicalStock} />
      <Route isPrivate path={`${url}/historic-stock-position`} component={HistoricStockPosition} />
      <Route isPrivate path={`${url}/stock-position`} component={StockPosition} />
      <Route isPrivate path={`${url}/position-entry-notes`} component={PositionEntryNotes} />
      <Route isPrivate path={`${url}/imei-query`} component={ImeiQuery} />
      <Route isPrivate path={`${url}/aging-pll-with-parts`} component={AgingPllWithParts} />
      <Route isPrivate path={`${url}/in-out-pll`} component={InOutPll} />
      <Route isPrivate path={`${url}/parts-pll`} component={PartsPLL} />
      <Route isPrivate path={`${url}/fg-consumed-parts`} component={FgConsumedParts} />
      <Route isPrivate path={`${url}/delivery-confirmation`} component={DeliveryConfirmation} />
      <Route isPrivate path={`${url}/non-compliance`} component={NonCompliance} />
      <Route isPrivate path={`${url}/receiving-parts`} component={ReceivingParts} />
      <Route isPrivate path={`${url}/summary-cmp`} component={SummaryCMP} />
      <Route isPrivate path={`${url}/unlock-history`} component={UnlockHistory} />
    </>
  );
};
