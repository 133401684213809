import { useRouteMatch } from 'react-router';

import { FinancialClose } from 'pages/Private/Financial/FinancialClose';

import { Route } from '../Route';

export const FinancialRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/financial-close`} component={FinancialClose} />
    </>
  );
};
