import { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { getAvatarPath } from 'helpers/getImagePath';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { useLayoutContext } from '../../context';
import { AvaliationField } from './AvaliationField';
import { PopOverInformations } from './PopOverInformations';
import { PopOverNotifications } from './PopOverNotifications';
import { useStyles } from './styles';

const Desktop = () => {
  const classes = useStyles();

  const avatarFilename = useContextSelector(
    AuthContext,
    (context) => context?.user?.avatar?.filename,
  );

  const expiresIn = localStorage.getItem('@Gaia:expiresIn');

  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    if (expiresIn) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const expirationTime = new Date(expiresIn * 1000);
        const timeLeft = expirationTime.getTime() - now.getTime();
        setCountdown(timeLeft > 0 ? timeLeft : 0);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [expiresIn]);

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (countdown !== null) {
    hours = Math.floor((countdown / 1000 / 60 / 60) % 24);
    minutes = Math.floor((countdown / 1000 / 60) % 60);
    seconds = Math.floor((countdown / 1000) % 60);
  }

  const { setAnchorEl, menuId } = useLayoutContext();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root}>
      <Grid style={{ marginTop: '25px' }}>
        {countdown !== null ? (
          <Typography variant='subtitle2'>
            {`Sessão expira em: ${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
              seconds < 10 ? '0' : ''
            }${seconds}`}
          </Typography>
        ) : (
          <Typography variant='subtitle2'>Loading...</Typography>
        )}
      </Grid>
      <IconButton style={{ marginTop: '10px' }} color='inherit'>
        <AvaliationField />
      </IconButton>
      <IconButton style={{ marginTop: '10px' }} color='inherit'>
        <Badge>
          <PopOverInformations />
        </Badge>
      </IconButton>
      <IconButton style={{ marginTop: '10px' }} color='inherit'>
        <Badge badgeContent={15} color='secondary'>
          <PopOverNotifications />
        </Badge>
      </IconButton>

      <IconButton
        edge='end'
        aria-label='account of current user'
        aria-controls={menuId}
        aria-haspopup='true'
        onClick={handleProfileMenuOpen}
        color='inherit'
      >
        <Avatar src={getAvatarPath(avatarFilename)} alt='Avatar do usuário' />
      </IconButton>
    </div>
  );
};

export default Desktop;
