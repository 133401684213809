import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogObs = ({ openDialogObs, handleCloseDialogObs, obsClient }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const updateObs = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await providersService.updateObsResubmissionCasesOpen({
        idTicket: props.values.idTicket,
        input_obs: props.values.input_obs,
        usuario: user.name,
      });
      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar');
    } finally {
      setIsLoading(false);
      handleCloseDialogObs();
    }
  };

  return (
    <Dialog open={openDialogObs}>
      <Dialog.Title onClose={handleCloseDialogObs}>Observação</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            idTicket: obsClient.idTicket,
            input_obs: obsClient.obs,
            usuario: user.name,
          }}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    name='input_obs'
                    variant='outlined'
                    component={Text}
                    multiline
                    minRows={8}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    onClick={() => updateObs(props)}
                    loading={isLoading}
                  >
                    Alterar Observação
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseDialogObs}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
