/* eslint-disable prettier/prettier */
import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { FormatDate } from 'components';
import { useQuery } from 'hooks/useQuery';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import { xpcellService } from 'services';

import useStyles from './styles';

export const BudgetMonth = () => {
  const widths = 900;
  const heights = 500;
  const classes = useStyles();

  const [sacDataRepproved, , loadingSacDataRepproved, refetchRepproved] = useQuery(() => xpcellService.getBudgetRepprovedGraphic(), []);

  const [sacDataApproved, , loadingSacDataApproved, refetchApproved] = useQuery(() => xpcellService.getBudgetApprovedMonthGraphic(), []);

  const graphicOne = [{
    Reprovado:  sacDataRepproved?.[0]?.valorTotalOrcamentos ,
    Aprovado: sacDataApproved?.[0]?.valorTotalOrcamentos,
    value_repproved: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sacDataRepproved?.[0]?.valorTotalOrcamentos),
    value_approved: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sacDataApproved?.[0]?.valorTotalOrcamentos),
  }];

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          className={classes.graphicText}
          x={x + width / 2}
          y={y - radius}
          fill='#000000'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {String(value)}
        </text>
      </g>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetchRepproved();
      refetchApproved()
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchRepproved, refetchApproved]);

  return (
    <Grid container spacing={4} className={classes.transform}>
      <Grid item xs={12} lg={12} className={classes.gaiaText}>
        <FormatDate format='dd/MM/yyyy'>{new Date()}</FormatDate>
      </Grid>
      {/* <Spacer size={24} /> */}
      <Grid item xs={12} lg={12}>
        {' '}
        <p className={classes.gaia}>APROVADOS X REPROVADOS</p>
      </Grid>
      <Grid item xs={12} lg={12} className={classes.graphicLine}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart width={widths} height={heights} data={graphicOne}>
            <CartesianGrid strokeDasharray='3 3' />
            {/* <XAxis dataKey='nome' /> */}
            <YAxis domain={[0, 'dataMax + 100000']} />
            <Tooltip />
            <Legend />
            <Bar dataKey='Aprovado' fill='#0dab05' minPointSize={5}>
              <LabelList dataKey='value_approved' content={renderCustomizedLabel} />
            </Bar>
            <Bar dataKey='Reprovado' fill='#de14cd' minPointSize={10}>
              <LabelList dataKey='value_repproved' content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
