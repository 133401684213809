import { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CheckIcon from '@mui/icons-material/Check';
import { Button, Spacer, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { validationSchema } from './validation';

const subColumns = [
  { label: 'Item', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Sku', align: 'center' },
  { label: 'Grade', align: 'center' },
];

const mockItens = [
  {
    id: 1,
    cProd: 'CV5014MC4A.AMBGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV5014MC4A,COM CAPACIDADE DE 14KG ROUPA SECA,220V,TITANIUM,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 418.11,
  },
  {
    id: 2,
    cProd: 'CV5014PC4A.APTGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV5014PC4A,COM CAPACIDADE DE 14KG ROUPA SECA,220V,PLATINUM,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 1150.92,
  },
  {
    id: 3,
    cProd: 'CV9014BC2.ABLFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV9014BC2,COM CAPACIDADE DE 14KG DE ROUPA SECA,127V,PRETO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 1354.13,
  },
  {
    id: 4,
    cProd: 'FV3011WG4.ABWFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV3011WG4,COM CAPACIDADE DE 11KG DE ROUPA SECA,127V,BRANCO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 555.45,
  },
  {
    id: 5,
    cProd: 'FV3011WG4A.ABWGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV3011WG4A,COM CAPACIDADE DE 11KG DE ROUPA SECA,220V,BRANCO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 563.85,
  },
  {
    id: 6,
    cProd: 'FV5013WC4.ABWFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV5013WC4,COM CAPACIDADE DE 13KG DE ROUPA SECA,127V,BRANCA,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 757.57,
  },
  {
    id: 7,
    cProd: 'FV5013WC4A.ABWGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV5013WC4A,COM CAPACIDADE DE 13KG DE ROUPA SECA,220V,BRANCA,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 747.36,
  },
];

export const ProductConference = () => {
  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    console.log('');
  };

  return (
    <Fragment>
      <PageTitle>Conferência de produtos</PageTitle>

      <Formik
        initialValues={{ invoice: '145076' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='invoice'
                    size='small'
                    label='Nota fiscal'
                    component={Text}
                    onInput={toTrim}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <Button
                    fullWidth
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    loading={props.isSubmitting}
                  >
                    Consultar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Spacer size={30} />
      <Table
        headers={subColumns}
        striped
        // loading={props.isSubmitting}
        emptyMessage='Nenhum registro encontrado.'
        data={mockItens}
        disableNumeration
      >
        {mockItens?.map((item, key) => (
          <TableRow key={key}>
            <TableCell align='center'>{item.id}</TableCell>
            <TableCell align='center'>{item.cProd}</TableCell>
            <TableCell align='center'>{item.xProd}</TableCell>
            <TableCell align='center'>{item.amount}</TableCell>
            <TableCell align='center'>
              <Text label='Sku' size='small' inputProps={{ maxLength: 15 }} />
            </TableCell>
            <TableCell align='center'>
              <Text label='Grade' size='small' inputProps={{ maxLength: 4 }} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Grid container justifyContent='flex-end'>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Button fullWidth endIcon={<CheckIcon fontSize='large' />} color='secondary'>
            Confirmar conferência
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};
