import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import { Button, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const typeProduct = [
  { label: 'NPC', value: '15' },
  { label: 'HHP', value: '0' },
  { label: 'Todos', value: '1' },
];

const typeProcess = [
  { label: 'Todos', value: '0' },
  { label: 'Refursbish', value: '2' },
  { label: 'Sem Refursbish', value: '1,6,7' },
  { label: 'RED', value: '6' },
  { label: 'SEG', value: '7' },
];

const typeSEG = [
  { label: 'Cliente Seguradora', value: 10 },
  { label: 'SIS', value: 12 },
  { label: 'PLL', value: 13 },
  { label: 'Ponto Net', value: 14 },
  { label: 'TROCAFONE', value: 16 },
  { label: 'ASURION', value: 18 },
  { label: 'Zurich CC', value: 19 },
  { label: 'Assurant CC', value: 21 },
];

export const Aging = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getAging({
        type_product: values.type_product,
        type_procees: values.type_procees,
        type_seg: values.type_seg,
        checkboxes: values.checkboxes,
        checkboxes2: values.checkboxes2,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { Aging: worksheet },
          SheetNames: ['Aging'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Aging_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Aging</PageTitle>

      <Formik
        initialValues={{
          type_product: '',
          type_procees: '',
          type_seg: [],
          checkboxes: [],
          checkboxes2: [],
        }}
        onSubmit={handleDownloadReport}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          useEffect(() => {
            if (values.type_product === '1') {
              setFieldValue('type_procees', '');
              setFieldValue('type_seg', []);
            }
          }, [values.type_product, setFieldValue]);

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    disabled={isLoading}
                    name='type_product'
                    label='Tipo de Produto'
                    variant='outlined'
                    options={typeProduct}
                    component={Select}
                  />
                </Grid>
                {values.type_product !== '1' && (
                  <>
                    <Grid item xs={12} lg={2}>
                      <Field
                        size='small'
                        disabled={isLoading}
                        name='type_procees'
                        label='Tipo de Processo'
                        variant='outlined'
                        options={typeProcess}
                        component={Select}
                      />
                    </Grid>
                    {values.type_procees === '7' && (
                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          disabled={isLoading}
                          name='type_seg'
                          label='Seguradora'
                          variant='outlined'
                          options={typeSEG}
                          component={Select}
                          multiple
                        />
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12} lg={6} container spacing={1}>
                  {['Excluir Cliente CenterCell', 'SAC'].map((label, index) => (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={
                          <Field
                            type='checkbox'
                            name='checkboxes'
                            as={Checkbox}
                            value={label}
                            checked={values.checkboxes.includes(label)}
                          />
                        }
                        label={label}
                      />
                    </Grid>
                  ))}
                  {['LP', 'OW'].map((label2, index2) => (
                    <Grid item key={index2}>
                      <FormControlLabel
                        control={
                          <Field
                            type='checkbox'
                            name='checkboxes2'
                            as={Checkbox}
                            value={label2}
                            checked={values.checkboxes2.includes(label2)}
                          />
                        }
                        label={label2}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={2} lg={2}>
                  <Button type='submit' fullWidth loading={isLoading}>
                    Exportar relatório
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
