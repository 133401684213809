import { api } from 'services/api';

export class AccountService {
  async listUsers() {
    return api.get('/users');
  }

  async updateUser(data) {
    return api.put('/users', data);
  }

  async listUsersAccessActive() {
    return api.get('/users/access/active');
  }

  async addViewedTutorial(tutorial) {
    return api.post('/users/tutorial', { tutorial });
  }

  async listUserRoles() {
    return api.get('/users/roles');
  }

  async createRole(data) {
    return api.post('/users/roles', data);
  }

  async updateRole(data) {
    return api.put('/users/roles', data);
  }

  async updateUserRolePermissions(id, allPermissions) {
    return api.put('/users/roles/permissions', { id, allPermissions });
  }

  async updateUserAdditionalPermissions(id, allAdditionalPermissions) {
    return api.put('/users/permissions/additional', { id, allAdditionalPermissions });
  }

  async rateAvaliation(commentary, last_rate) {
    return api.post('/users/ratings', { commentary, last_rate });
  }

  async getRoleById(id) {
    return api.get(`/users/roles/${id}`);
  }
}

export const accountService = new AccountService();
