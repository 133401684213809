import { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Spacer, Table, Tabs, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
const subColumns = [
  { label: 'Item', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Deletar', align: 'center' },
];

const mockItens = [
  {
    id: 1,
    cProd: 'SAM/A54',
    xProd: 'Samsung A54',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 418.11,
  },
];

export const OrderProduct = () => {
  return (
    <Fragment>
      <PageTitle>Encomendar produto</PageTitle>

      <Tabs>
        <Tabs.Content label='Encomendar' disablePadding>
          <Spacer size={30} />
          <Formik
            initialValues={{ product: '' }}
            // validationSchema={validationSchema}
            // onSubmit={onSubmitHandler}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                      <Field
                        variant='outlined'
                        name='product'
                        size='small'
                        label='Pesquisar produto'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      <Button
                        fullWidth
                        disabled={!props.isValid || !props.dirty}
                        type='submit'
                        loading={props.isSubmitting}
                        endIcon={<AddIcon />}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
          <Spacer size={30} />
          <Table
            headers={subColumns}
            striped
            // loading={props.isSubmitting}
            emptyMessage='Nenhum registro encontrado.'
            data={mockItens}
            disableNumeration
          >
            {mockItens?.map((item, key) => (
              <TableRow key={key}>
                <TableCell align='center'>{item.id}</TableCell>
                <TableCell align='center'>{item.cProd}</TableCell>
                <TableCell align='center'>{item.xProd}</TableCell>
                <TableCell align='center'>{item.amount}</TableCell>
                <TableCell align='center'>
                  <IconButton>
                    <DeleteIcon sx={{ color: 'red' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Tabs.Content>
        <Tabs.Content label='Andamento' disablePadding></Tabs.Content>
      </Tabs>
    </Fragment>
  );
};
