import React from 'react';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@mui/lab/Autocomplete';
import { createFilterOptions } from '@mui/material';
import { Field } from 'formik';
import { fieldToTextField } from 'formik-mui';

import { useStyles } from './styles';

const FormikAutocomplete = ({ textFieldProps, onChange, ...props }) => {
  const classes = useStyles();

  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name, label } = field;

  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    options.unshift({
      label: state?.inputValue,
      value: state?.inputValue,
    });

    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Autocomplete
      {...props}
      {...field}
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando'
      //
      disablePortal
      //
      filterOptions={filterOptions}
      onChange={(_, value) => {
        setFieldValue(name, value);
        // if (onChange) {
        //   onChange(value);
        // }
      }}
      onBlur={() => setTouched({ [name]: true })}
      getOptionSelected={(item, current) => item?.value === current?.value}
      onInputChange={(event, newValue) => {
        // handle the onChange event while typing
        // setFieldValue(name, newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={label}
          className={classes.customInput}
        />
      )}
    />
  );
};

const CustomAutocomplete = ({ name, label, options, ...props }) => (
  <>
    <Field
      {...props}
      name={name}
      component={FormikAutocomplete}
      label={label}
      options={options}
      textFieldProps={{
        fullWidth: true,
        variant: 'outlined',
      }}
    />
  </>
);

export { CustomAutocomplete };

// import React, { useMemo, useState } from 'react';

// import { useField } from 'formik';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';

// import Autocomplete from '@mui/material/Autocomplete';
// import { UseDebounce } from 'hooks/useDebounce';

// import { getFieldError } from 'helpers/formik';

// export const CustomAutoComplete = ({ isExternalLoading }) => {
//   const {  } = useField()
//   const { debounce } = UseDebounce();

//   const [options, setOptions] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selected, setSelected] = useState();

//   const [search, setSearch] = useState('');

//   const autoCompleteSelectedOption = useMemo(() => {
//     if (!selected) return null;

//     const selectedOption = options.find((option) => option === selected);

//     if (!selectedOption) return null;

//     return selectedOption;
//   }, [selected, options]);

//   return (
//     <Autocomplete
//       openText='Abrir'
//       closeText='Fechar'
//       noOptionsText='Sem opções'
//       loadingText='Carregando'
//       //
//       disablePortal
//       //
//       value={autoCompleteSelectedOption}
//       options={options}
//       disabled={isExternalLoading}
//       loading={isLoading}
//       onInputChange={(_, newValue) => setSearch(newValue)}
//       popupIcon={isExternalLoading || isLoading ? <CircularProgress size={24} /> : undefined}
//       onChange={(_, newValue) => {
//         setSelected(newValue);
//         setSearch('');
//         clearError();
//       }}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           error={!!error}
//           helperText={error}
//           defaultValue={defaultValue}
//           label='Cidade'
//         />
//       )}
//     />
//   );
// };
