import { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const typeSelect = [
  { label: 'Checkkit', value: '0' },
  { label: 'Orçamento', value: '1' },
  { label: 'Orçamento/Serviços', value: '2' },
];

const typeSeg = [
  { label: 'Todos', value: 0 },
  { label: 'ASSURANT CC', value: 21 },
  { label: 'ASURION', value: 18 },
  { label: 'PLL', value: 13 },
  { label: 'PONTO NET', value: 14 },
  { label: 'SIS', value: 12 },
  { label: 'TROCAFONE', value: 16 },
  { label: 'ZURICH CC', value: 19 },
];

export const SamsungBudgetReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getSamsungBudgetReport({
        type_report: values.type_report,
        type_seg: values.type_seg,
        input_data: values.input_data,
        output_data: values.output_data,
        checkboxes: values.checkboxes,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { SamsungBudgetReport: worksheet },
          SheetNames: ['SamsungBudgetReport'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Orçamentos_Samsung_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Orçamentos Samsung</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          type_seg: '',
          input_data: new Date(),
          output_data: new Date(),
          checkboxes: [],
        }}
        onSubmit={handleDownloadReport}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_report'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_seg'
                  label='Tipo do Seguradora'
                  variant='outlined'
                  options={typeSeg}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4} container spacing={1}>
                {['Execução do Reparo'].map((label, index) => (
                  <Grid item key={index}>
                    <FormControlLabel
                      control={
                        <Field
                          type='checkbox'
                          name='checkboxes'
                          as={Checkbox}
                          value={label}
                          checked={values.checkboxes.includes(label)}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={2} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
