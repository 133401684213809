import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { releasesService } from '../../../../../services/releases';

const validationSchema = yup.object().shape({
  input_desbloqueio: yup.string().required('Motivo do desbloqueio é obrigatório'),
});

export const DialogUnlock = ({ openDialogUnlock, handleCloseOpenDialogUnlock, infoOs }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const newUnlock = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await releasesService.getNewUnlock({
        idBloqueio: props.values.idBloqueio,
        obs: props.values.input_desbloqueio,
        usuario: user.name,
      });
      toast.success('Desbloqueado com sucesso');
    } catch (error) {
      toast.error('Erro ao desbloqueiar');
    } finally {
      setIsLoading(false);
      handleCloseOpenDialogUnlock();
    }
  };

  return (
    <Dialog open={openDialogUnlock}>
      <Dialog.Title onClose={handleCloseOpenDialogUnlock}>Desbloqueio de OS</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            idBloqueio: infoOs.idBloqueio,
            input_desbloqueio: '',
            usuario: user.name,
          }}
          validationSchema={validationSchema}
          onSubmit={newUnlock}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                    ID Bloqueio
                  </Typography>
                  <Typography variant='h6' align='center' name='idBloqueio'>
                    {infoOs.idBloqueio}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    name='input_desbloqueio'
                    variant='outlined'
                    label='Motivo do desbloqueio'
                    component={Text}
                    multiline
                    minRows={8}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    loading={isLoading}
                    onClick={() => newUnlock(props)}
                  >
                    Desbloquear
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseOpenDialogUnlock}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
