import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TextField from '@mui/material/TextField';
import { Button, Table } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { DialogEdit } from './Dialog/DialogEdit';
import { DialogObs } from './Dialog/DialogObs';

const columns = [
  { label: 'ID' },
  { label: 'Num.OS' },
  { label: 'OS.Parceiro' },
  { label: 'CPF' },
  { label: 'Nome' },
  { label: 'Endereço' },
  { label: 'Numero' },
  { label: 'Complemento' },
  { label: 'Bairro' },
  { label: 'CEP' },
  { label: 'Cidade' },
  { label: 'UF' },
  { label: 'Motivo' },
  { label: 'Obs' },
  { label: 'Reabrir' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const ResubmissionCasesUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterText, setFilterText] = useState('');
  const [openDialogObs, setDialogObs] = useState(false);
  const [obsClient, setObsClient] = useState([]);
  const [openDialogEdit, setDialogEdit] = useState(false);
  const [editObs, setEditObs] = useState([]);

  const dataTable = async () => {
    try {
      const response = await providersService.getResubmissionCasesUpdate();
      const formattedData = response.data.map((item) => ({
        ...item,
        dtCadastro: formatDate(item.dtCadastro), // Aplicando formatação aqui
      }));
      setData(formattedData);
    } catch (error) {
      console.log('Erro ao buscar dados:', error);
    }
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getResubmissionCasesUpdate();

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { ResubmissionCasesUpdate: worksheet },
          SheetNames: ['ResubmissionCasesUpdate'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Casos_Reenvio_Atualizado_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dataTable();
  }, []);

  const filteredData = useMemo(() => {
    const lowercasedFilter = filterText.toLowerCase();
    return filterText
      ? data.filter((item) => {
          return (
            (item.idTicket && item.idTicket.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.numeroOs && item.numeroOs.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.osParceiro &&
              item.osParceiro.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.cpf && item.cpf.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novaUF && item.novaUF.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoLogradouro &&
              item.novoLogradouro.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoNro && item.novoNro.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoCompl &&
              item.novoCompl.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoBairro &&
              item.novoBairro.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoCep && item.novoCep.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novaCidade &&
              item.novaCidade.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.novoNome && item.novoNome.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.motivo && item.motivo.toString().toLowerCase().includes(lowercasedFilter))
          );
        })
      : data;
  }, [data, filterText]);

  const currentPageData = useMemo(() => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialogObs = (item) => {
    setDialogObs(true);
    setObsClient(item);
  };

  const handleCloseDialogObs = () => {
    setDialogObs(false);
  };

  const handleOpenDialogEdit = (item) => {
    setDialogEdit(true);
    setEditObs(item);
  };

  const handleCloseDialogEdit = () => {
    setDialogEdit(false);
  };

  return (
    <>
      <DialogObs
        openDialogObs={openDialogObs}
        handleCloseDialogObs={handleCloseDialogObs}
        obsClient={obsClient}
      />
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        editObs={editObs}
      />
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        style={{ paddingBottom: '2rem' }}
      >
        <PageTitle>Casos de reenvio - Atualizado</PageTitle>
        <Grid
          item
          xs={6}
          lg={4}
          container
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={6}>
            <Button fullWidth onClick={handleDownloadReport} loading={isLoading}>
              Exportar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              variant='outlined'
              label='Pesquisar'
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12}>
          <Table name='Table' headers={columns} maxHeight disableNumeration>
            {currentPageData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.idTicket}</TableCell>
                <TableCell>{item.numeroOs}</TableCell>
                <TableCell>{item.osParceiro}</TableCell>
                <TableCell>{item.cpf}</TableCell>
                <TableCell>{item.novoNome}</TableCell>
                <TableCell>{item.novoTipoLogradouro + ' ' + item.novoLogradouro}</TableCell>
                <TableCell>{item.novoNro}</TableCell>
                <TableCell>{item.novoCompl}</TableCell>
                <TableCell>{item.novoBairro}</TableCell>
                <TableCell>{item.novoCep}</TableCell>
                <TableCell>{item.novaCidade}</TableCell>
                <TableCell>{item.novaUF}</TableCell>
                <TableCell>{item.motivo}</TableCell>
                <TableCell>
                  <RemoveRedEyeIcon
                    onClick={() => handleOpenDialogObs(item)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell>
                  <EditIcon
                    onClick={() => handleOpenDialogEdit(item)}
                    style={{ cursor: 'pointer', color: '#512da8' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};
