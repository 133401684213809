import { api } from 'services/api';

export class XpcellService {
  //FINANCIAL CLOSING
  async searchFinancialClosing(data) {
    return await api.get('/xpcell/financial/closing', {
      params: {
        date: data,
      },
    });
  }

  async createFinancialClosing(data) {
    return await api.post('/xpcell/financial/closing', data);
  }

  async getClosingTypes() {
    return await api.get('/xpcell/financial/closing/types');
  }

  //GRAPHIC
  async getBudgetApprovedGraphic() {
    return await api.get('/xpcell/sac/graphic/budget-approved');
  }

  async getBudgetApprovedMonthGraphic() {
    return await api.get('/xpcell/sac/graphic/budget-approved-month');
  }

  async getBudgetRepprovedGraphic() {
    return await api.get('/xpcell/sac/graphic/budget-repproved');
  }

  //INVOICES
  async createNewKitInvoice(data) {
    return await api.post('/xpcell/invoices/new/kit', data);
  }

  async getClient(code, interprise) {
    return await api.get(`/xpcell/invoices/new/kit/${code}/${interprise}`);
  }

  async validatedServiceOrderForFinancialClosing(transmission_data) {
    return await api.post('/xpcell/financial/closing/validated', transmission_data);
  }

  //STATUS
  async getStatusGspn() {
    return await api.get('/xpcell/gspn/status');
  }

  async getSubStatusGspn() {
    return await api.get('/xpcell/gspn/sub/status');
  }
}

export const xpcellService = new XpcellService();
