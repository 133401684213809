import { api } from 'services/api';

export class UploadImagesDashService {
  async upload(data) {
    return api.post('imagesDashboard/upload/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async getImages() {
    return api.get('imagesDashboard/getImages');
  }
}

export const uploadImagesDashService = new UploadImagesDashService();
