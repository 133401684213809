import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { uploadImagesDashService } from '../../../../services/uploadImagesDash';

export const UploadImage = () => {
  const fileInputRefs = useRef([]);
  const [fileMap, setFileMap] = useState({});

  const handleFileInputChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith('image/')) {
      toast.error('Por favor, selecione um arquivo de imagem.');
      return;
    }

    // Cria um novo arquivo com o nome desejado
    const renamedFile = new File([file], `imagem${index + 1}.png`, { type: file.type });

    setFileMap((prev) => ({ ...prev, [index]: renamedFile }));
    toast.info(`Imagem ${index + 1} selecionada.`);
  };

  const handleUploadFileClick = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  const handleSubmit = async (values) => {
    try {
      // Verifica se há arquivos a serem enviados
      if (Object.keys(fileMap).length === 0) {
        toast.warning('Nenhuma imagem selecionada.');
        return;
      }

      const uploadPromises = Object.values(fileMap).map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const { data } = await uploadImagesDashService.upload(formData);
          return data;
        } catch (error) {
          console.error(error.message);
          toast.error('Erro ao enviar uma imagem.');
          throw error;
        }
      });

      await Promise.all(uploadPromises);
      toast.success('Todas as imagens foram enviadas com sucesso.');
      // Limpa o mapa de arquivos após o envio
      setFileMap({});
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao enviar imagens.');
    }
  };

  return (
    <>
      <PageTitle>Upload de imagens</PageTitle>

      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <Grid container spacing={2}>
            {[...Array(5).keys()].map((index) => (
              <Grid item xs={12} lg={2} key={index}>
                <input
                  type='file'
                  ref={(el) => (fileInputRefs.current[index] = el)}
                  style={{ display: 'none' }}
                  accept='image/*'
                  onChange={(event) => handleFileInputChange(event, index)}
                />
                <Box mb={2}>
                  <Button fullWidth variant='outlined' onClick={() => handleUploadFileClick(index)}>
                    Selecionar arquivo {index + 1}
                  </Button>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12} lg={2}>
              <Button fullWidth type='submit'>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
