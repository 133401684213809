import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { ReactComponent as Delete } from 'assets/images/undraw_throw_away_re_x60k.svg';
import { Button, Checkbox, Spacer, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const DeletePartsGspn = () => {
  const classes = useStyles();

  const [getServiceOrder, setServiceOrder] = useState('');
  const [getParts, setParts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getSelectedRow, setSelectedRow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [getSelectedAll, setSelectedAll] = useBoolean(false);

  const updateAllRowsChecked = () => {
    setSelectedRow([]);
    if (!getSelectedAll) {
      const updatedRow = [];
      getParts.map((item, key) => {
        updatedRow.push(item);
      });
      setSelectedRow(updatedRow);
    }
    setSelectedAll.toggle();
  };

  const columns = [
    {
      label: (
        <Checkbox
          color='secondary'
          checked={getSelectedAll}
          onChange={() => updateAllRowsChecked()}
        />
      ),
      align: 'center',
    },
    { label: 'N°', align: 'center' },
    { label: 'Part number', align: 'center' },
    { label: 'Descrição', align: 'center' },
    { label: 'Quantidade', align: 'center' },
  ];

  const updateRowsChecked = (item) => {
    const updatedRow = [...getSelectedRow];

    const index = updatedRow.indexOf(item);

    if (index !== -1) {
      updatedRow.splice(index, 1);
      setSelectedAll.toFalse();
    } else {
      updatedRow.push(item);
    }

    if (updatedRow.length === getParts.length) {
      setSelectedAll.toTrue();
    }

    setSelectedRow(updatedRow);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const resetStates = () => {
    setParts();
    setSelectedRow([]);
    setSelectedAll.toFalse();
    setServiceOrder('');
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    toast.info('Pesquisando a ordem de serviço...');
    resetStates();
    setSubmitting(true);
    try {
      const { service_order } = values;

      const { data } = await providersService.getPartsGspn(service_order);
      setParts(data);
      setServiceOrder(service_order);

      toast.success('Ordem de serviço encontrada com sucesso!');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao atualizar o status no GSPN');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteParts = async () => {
    setIsLoading(true);
    toast.warning('Deletando as peças selecionados no GSPN...');
    try {
      // await providersService.deletePartsGspn(getServiceOrder, getSelectedRow);
      resetStates();
      toast.success('Peças deletadas com sucesso!');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao deletar as peças no GSPN');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper>
      <PageTitle>Deletar peças lançadas no GSPN</PageTitle>
      <Spacer size={30} />
      <Formik
        initialValues={{ service_order: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='service_order'
                    size='small'
                    label='Ordem de Serviço'
                    inputProps={{ maxLength: 50, minlength: 1 }}
                    component={Text}
                    onInput={toTrim}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <Button
                    fullWidth
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    loading={props.isSubmitting}
                  >
                    Consultar
                  </Button>
                </Grid>
                {getParts ? (
                  <>
                    <Spacer size={100} />
                    <Grid item xs={12}>
                      <Table
                        headers={columns}
                        striped
                        loading={props.isSubmitting}
                        emptyMessage='Nenhum registro encontrado.'
                        data={getParts}
                        disableNumeration
                      >
                        {getParts?.map((component, key) => (
                          <TableRow key={key}>
                            <TableCell align='center'>
                              <Checkbox
                                checked={
                                  getSelectedAll ||
                                  getSelectedRow?.some((item) => item === component)
                                }
                                onChange={() => updateRowsChecked(component)}
                              />
                            </TableCell>
                            <TableCell align='center'>{component?.SeqNo}</TableCell>
                            <TableCell align='center'>{component?.PartsNo}</TableCell>
                            <TableCell align='center'>{component?.PartsDesc}</TableCell>
                            <TableCell align='center'>{component?.PartsQty}</TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Grid>
                    <Grid container justifyContent='space-between'>
                      <Grid>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 50, 100, 200]}
                          component='div'
                          count={getParts?.length}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage='Linhas por página:'
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count !== -1 ? count : ''}`
                          }
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          fullWidth
                          color='secondary'
                          disabled={getSelectedRow.length <= 0}
                          onClick={() => handleDeleteParts()}
                          loading={isLoading}
                        >
                          Deletar peças selecionadas
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Form>
          </>
        )}
      </Formik>

      <Grid className={classes.containerImg} container>
        <Delete className={classes.deleteImage} />
      </Grid>
    </Paper>
  );
};
