import { useRouteMatch } from 'react-router';

import { DevolutionInvoice } from 'pages/Private/Stock/DevolutionInvoice';
import { DevolutionInvoiceUnclass } from 'pages/Private/Stock/DevolutionInvoiceUnclass';

import { Route } from '../Route';

export const StockRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/devolution-invoice`} component={DevolutionInvoice} />
      <Route
        isPrivate
        path={`${path}/devolution-invoiceUnclass`}
        component={DevolutionInvoiceUnclass}
      />
    </>
  );
};
