import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { ReactComponent as Upgrade } from 'assets/images/undraw_os_upgrade_re_r0qa.svg';
import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { xpcellService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const interpriseOptions = [
  {
    label: 'CENTER CELL',
    value: 'CENTER CELL',
  },
  {
    label: 'ICF COMERCIO',
    value: 'ICF COMERCIO',
  },
];

export const NewKit = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const handleVerifyCode = async ({ setSubmitting, setFieldValue, values }) => {
    toast.info('Validando o código do cliente...');
    setSubmitting(true);
    try {
      const { data } = await xpcellService.getClient(values?.client_cod, values?.interprise);

      setFieldValue('client', data.NOME);

      toast.success('Cliente encontrado');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao validar o código do cliente');
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    toast.info('Gerando NF de kit novo...');
    setSubmitting(true);
    setLoading(true);
    try {
      const { data } = await xpcellService.createNewKitInvoice(values);

      if (data?.link_nfe) {
        window.open(data.link_nfe, '_blank');
        toast.success(`NF ${data?.numero} gerada com sucesso`);
      } else {
        toast.error('Não foi possível encontrar o link da NF');
      }
      setLoading(false);
      resetForm();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao gerar a NF de kit novo.');
      setLoading(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Kit novo</PageTitle>
      {/* <Grid container spacing={2} justifyContent='flex-start'>
        <Grid item xs={6} sm={6} md={6} lg={2}>
          <Button fullWidth variant='contained' color='primary'>
            Importar planilha
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={2}>
          <Button fullWidth variant='outlined' color='secondary' s>
            Exportar modelo
          </Button>
        </Grid>
      </Grid> */}
      <Spacer size={25} />
      <Grid container spacing={2}>
        <Grid item sm={6} lg={6}>
          <Paper elevation={3} style={{ padding: 50 }}>
            <Formik
              initialValues={{
                interprise: '',
                service_order: '',
                client_cod: '',
                client: '',
                invoice: '',
                product: '',
                imei: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {(props) => (
                <>
                  <Form onSubmit={props.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={12}>
                        <Field
                          variant='outlined'
                          name='interprise'
                          size='small'
                          label='Empresa'
                          component={Select}
                          options={interpriseOptions}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='service_order'
                          size='small'
                          label='Ordem de serviço'
                          inputProps={{ maxLength: 50, minlength: 1 }}
                          component={Text}
                          onInput={toTrim}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='imei'
                          size='small'
                          label='IMEI/SERIAL'
                          inputProps={{ maxLength: 50, minlength: 1 }}
                          component={Text}
                          onInput={toTrim}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='client_cod'
                          size='small'
                          label='Código do cliente'
                          inputProps={{ maxLength: 50, minlength: 1 }}
                          component={Text}
                          onInput={toTrim}
                          onBlur={() => handleVerifyCode(props)}
                          disabled={!props.values.interprise}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='client'
                          size='small'
                          label='Cliente'
                          component={Text}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='invoice'
                          size='small'
                          label='Nota fiscal'
                          inputProps={{
                            maxLength: 50,
                            minlength: 1,
                          }}
                          component={Text}
                          onInput={toTrim}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field
                          variant='outlined'
                          name='product'
                          size='small'
                          label='Produto'
                          inputProps={{ maxLength: 50, minlength: 1 }}
                          component={Text}
                          onInput={toTrim}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ display: 'grid', justifyContent: 'center' }}
                      >
                        <Button
                          disabled={!props.isValid || !props.dirty}
                          type='submit'
                          variant='contained'
                          color='secondary'
                          loading={loading}
                        >
                          Emitir nota de kit novo
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid className={classes.containerImg} item sm={6} lg={6}>
          <Upgrade className={classes.trashImage} />
        </Grid>
      </Grid>
    </>
  );
};
