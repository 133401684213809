import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },

  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },

  tableRoot: ({ maxHeight }) => ({
    width: '100%',
    maxHeight: maxHeight || 'none',
    marginBottom: theme.spacing(2),
  }),
  tableRowRoot: {
    '&:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  },
  head: {
    backgroundColor: '#fafafa',
  },
}));
