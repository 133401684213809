import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@mui/material/TextField';
import { Button, DatePicker, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';
import * as yup from 'yup';

import { releasesService } from '../../../../services/releases';
import { DialogBlock } from './Dialog/DialogBlock';
import { DialogEdit } from './Dialog/DialogEdit';
import { DialogUnlock } from './Dialog/DialogUnlock';

const columns = [
  { label: 'idBloqueio' },
  { label: 'ValorBloqueado' },
  { label: 'Tipo Bloqueio' },
  { label: 'Tipo Log Bloqueio' },
  { label: 'Data Bloqueio' },
  { label: 'Bloquado Por' },
  { label: 'Observação' },
  { label: 'Ciente' },
  { label: 'Desbloquear' },
  { label: 'Alterar OBS' },
];

const validationSchema = yup.object().shape({
  input_data: yup.string().required('Data inicial é obrigatório'),
  output_data: yup.string().required('Data final é obrigatório'),
});

export const BlockOs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState('');
  const [openDialogBlock, setOpenDialogBlock] = useState(false);
  const [openDialogUnlock, setOpenDialogUnlock] = useState(false);
  const [infoOs, setInfoOs] = useState([]);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDowloadTable = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await releasesService.getBlockOs({
        input_data: values.input_data,
        output_data: values.output_data,
      });
      toast.success('Tabela gerada com sucesso!');
      setTableData(data);
    } catch (error) {
      console.error('Erro ao gerar a tabela: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await releasesService.getBlockOs({
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { BlockOs: worksheet },
          SheetNames: ['BlockOs'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Bloqueio_OS_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    const lowercasedFilter = filterText.toLowerCase();
    return filterText
      ? tableData.filter((item) => {
          return (
            (item.idBloqueio &&
              item.idBloqueio.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.valorBloqueado &&
              item.valorBloqueado.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.descTipoBloqueio &&
              item.descTipoBloqueio.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.descTipoLogBloqueio &&
              item.descTipoLogBloqueio.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.dtBloqueio &&
              item.dtBloqueio.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.bloqueadoPor &&
              item.bloqueadoPor.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.obsBloqueio &&
              item.obsBloqueio.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.Cliente && item.Cliente.toString().toLowerCase().includes(lowercasedFilter))
          );
        })
      : tableData;
  }, [tableData, filterText]);

  const currentPageData = useMemo(() => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, page, rowsPerPage]);

  const handleOpenDialogBlock = () => {
    setOpenDialogBlock(true);
  };

  const handleCloseDialogBlock = () => {
    setOpenDialogBlock(false);
  };

  const handleOpenDialogUnlock = (item) => {
    setOpenDialogUnlock(true);
    setInfoOs(item);
  };

  const handleCloseOpenDialogUnlock = () => {
    setOpenDialogUnlock(false);
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setInfoOs(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };

  return (
    <>
      <DialogBlock
        openDialogBlock={openDialogBlock}
        handleCloseDialogBlock={handleCloseDialogBlock}
      />
      <DialogUnlock
        openDialogUnlock={openDialogUnlock}
        handleCloseOpenDialogUnlock={handleCloseOpenDialogUnlock}
        infoOs={infoOs}
      />
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        infoOs={infoOs}
      />
      <PageTitle>Bloqueio e Desbloqueio de OS</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
          usuario: user.name,
        }}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <Button
                  fullWidth
                  loading={isLoading}
                  onClick={() => {
                    handleDowloadTable(values);
                  }}
                >
                  Gerar Tabela
                </Button>
              </Grid>
              <Grid item xs={12} lg={1}>
                <Button fullWidth loading={isLoading} onClick={() => handleDownloadReport(values)}>
                  Relatorio
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button color='secondary' onClick={handleOpenDialogBlock}>
                  Bloqueio de OS
                </Button>
              </Grid>
              <Grid item xs={6} lg={3}>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  label='Pesquisar'
                  value={filterText}
                  onChange={(event) => setFilterText(event.target.value)}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {currentPageData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.idBloqueio}</TableCell>
                        <TableCell>{item.valorBloqueado}</TableCell>
                        <TableCell>{item.descTipoBloqueio}</TableCell>
                        <TableCell>{item.descTipoLogBloqueio}</TableCell>
                        <TableCell>{item.dtBloqueio}</TableCell>
                        <TableCell>{item.bloqueadoPor}</TableCell>
                        <TableCell>{item.obsBloqueio}</TableCell>
                        <TableCell>{item.Cliente}</TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            color='primary'
                            style={{ width: '135px' }}
                            onClick={() => handleOpenDialogUnlock(item)}
                          >
                            Desbloquear
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            color='secondary'
                            style={{ width: '135px' }}
                            onClick={() => handleOpenDialogEdit(item)}
                          >
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
