import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, editObs }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const newObs = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await providersService.newObsResubmissionCasesUpdate({
        idTicket: props.values.idTicket,
        input_new_obs: props.values.input_new_obs,
        usuario: user.name,
      });
      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar');
    } finally {
      setIsLoading(false);
      handleCloseDialogEdit();
    }
  };

  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>
          Insira uma observação para a reabertura
        </Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              idTicket: editObs.idTicket,
              input_new_obs: '',
              usuario: user.name,
            }}
            onSubmit={newObs}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='input_new_obs'
                      variant='outlined'
                      component={Text}
                      multiline
                      minRows={10}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button
                      fullWidth
                      color='secondary'
                      onClick={() => newObs(props)}
                      loading={isLoading}
                    >
                      Atualizar
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button fullWidth color='primary' onClick={handleCloseDialogEdit}>
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
