/* eslint-disable import-helpers/order-imports */
import axios from 'axios';
import { AuthTokenError } from 'services/errors/AuthTokenError';
import { API_URL } from 'settings';
let isRefreshing = false;

import { browserHistory } from 'routes/browserHistory';
let failedRequestsQueue = [];

function setupAPIClient() {
  const token = localStorage.getItem('@Gaia:token');

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // let logoutTimeoutId;

  api.interceptors.response.use(
    (response) => {
      // Cancel the logout timeout if there is a successful API response
      // clearTimeout(logoutTimeoutId);

      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        if (error.response.data?.message === 'Token expirado') {
          const refresh_token = localStorage.getItem('@Gaia:refresh_token');
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post('/refresh-token', {
                token: refresh_token,
              })
              .then((response) => {
                const { token, refresh_token } = response.data;

                localStorage.setItem('@Gaia:token', token);
                localStorage.setItem('@Gaia:refresh_token', refresh_token);

                api.defaults.headers['Authorization'] = `Bearer ${token}`;

                failedRequestsQueue.forEach((request) => request.onSuccess(token));
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) => request.onFailure(err));
                failedRequestsQueue = [];

                if (process.browser) {
                  localStorage.removeItem('@Gaia:token');
                  localStorage.removeItem('@Gaia:user');
                  browserHistory.push('/');
                  window.location.reload();
                }
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token) => {
                originalConfig.headers['Authorization'] = `Bearer ${token}`;

                resolve(api(originalConfig));
              },
              onFailure: (err) => {
                reject(err);
              },
            });
          });
        } else {
          if (process.browser) {
            // TODO Resolver logout por erro
            console.log('Process Browser Auth');
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    },
  );

  return api;
}

export const api = setupAPIClient();
