import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';
import * as Yup from 'yup';

const typeSelect = [
  { label: 'Detalhado', value: 'detalhado' },
  { label: 'Resumido', value: 'resumido' },
];

const typeProduct = [
  { label: 'Notebook', value: 15 },
  { label: 'Celular', value: 2 },
  { label: 'Tablet', value: 5 },
];

const validationSchema = Yup.object({
  type_report: Yup.string().required('Tipo do relatório é obrigatório'),
  input_data: Yup.date()
    .nullable()
    .required('Data inicial é obrigatória')
    .transform((value, originalValue) => {
      // Tenta fazer a conversão da string para uma data
      const parsedDate = new Date(originalValue);
      // Se a conversão for bem-sucedida e a data for válida, retorna a data
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
      // Caso contrário, retorna null, o que acionará a validação de erro
      return null;
    }),
  output_data: Yup.date()
    .nullable()
    .required('Data final é obrigatória')
    .transform((value, originalValue) => {
      const parsedDate = new Date(originalValue);

      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }

      return null;
    })
    .min(Yup.ref('input_data'), 'Data final deve ser posterior à data inicial'),
});

export const ProductionBySector = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getProductionBySector({
        type_report: values.type_report,
        type_product: values.type_product,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { ProductionBySector: worksheet },
          SheetNames: ['ProductionBySector'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Produto_Por_Setor_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Produção Por Setor</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          type_product: [],
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={handleDownloadReport}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_report'
                label='Tipo do relatório'
                variant='outlined'
                options={typeSelect}
                component={Select}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_product'
                label='Tipo de Pruduto'
                variant='outlined'
                options={typeProduct}
                component={Select}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
