import { api } from 'services/api';

export class ProvidersService {
  //PARTS
  async getPartsGspn(service_order) {
    return await api.get(`/ipaas/GetSOPartsInfo/${service_order}`);
  }

  async deletePartsGspn(service_order, parts) {
    return await api.post('/ipaas/DeleteSOParts', {
      service_order,
      parts,
    });
  }

  //STATUS
  async updatedStatusGspn(data) {
    return await api.post('/ipaas/changeSO/status', data);
  }

  //REPORTS
  async getOpenServiceOrderReport(data) {
    return api.post(`/xpcell-reports/open-service-order`, data);
  }

  async getProductionBySector(data) {
    return api.post(`/xpcell-reports/production-by-sector`, data);
  }

  async getObjectsReceived(data) {
    return api.post(`/xpcell-reports/objects-received`, data);
  }

  async getCheckinProduction(data) {
    return api.post(`/xpcell-reports/checkin-production`, data);
  }

  async getProductionByTechnician(data) {
    return api.post(`/xpcell-reports/production-by-technician`, data);
  }

  async getQualityControl(data) {
    return api.post(`/xpcell-reports/quality-control`, data);
  }

  async getOsOpeningErrors(data) {
    return api.post(`/xpcell-reports/os-opening-errors`, data);
  }

  async getAgingWithParts(data) {
    return api.post(`/xpcell-reports/aging-with-parts`, data);
  }

  async getGeneralBudgets(data) {
    return api.post(`/xpcell-reports/general-budgets`, data);
  }

  async getInOutReport(data) {
    return api.post(`/xpcell-reports/in-out`, data);
  }

  async getAgingFG(data) {
    return api.post(`/xpcell-reports/aging-fg`, data);
  }

  async getAging(data) {
    return api.post(`/xpcell-reports/aging`, data);
  }

  async getSamsungBudgetReport(data) {
    return api.post(`/xpcell-reports/samsung-budget-report`, data);
  }

  async getResubmissionCasesOpen() {
    return api.post(`/xpcell-reports/resubmission-cases-open`);
  }

  async updateObsResubmissionCasesOpen(data) {
    return api.post(`/xpcell-reports/update-obs-resubmission-cases-open`, data);
  }

  async updateClientResubissionCasesOpen(data) {
    return api.post(`/xpcell-reports/update-client-resubmission-cases-open`, data);
  }

  async hideClientRusubissionCasesOpen(data) {
    return api.post(`/xpcell-reports/hide-client-resubmission-cases-open`, data);
  }

  async reopenClientRusubissionCasesOpen(data) {
    return api.post(`/xpcell-reports/reopen-client-resubmission-cases-open`, data);
  }

  async getResubmissionCasesUpdate() {
    return api.post(`/xpcell-reports/resubmission-cases-updated`);
  }

  async newObsResubmissionCasesUpdate(data) {
    return api.post(`/xpcell-reports/new-obs-resubmission-cases-updated`, data);
  }

  async getAging4hrs(data) {
    return api.post(`/xpcell-reports/aging-4hrs`, data);
  }

  async getOsAssignment(data) {
    return api.post(`/xpcell-reports/os-assignment`, data);
  }

  async getBilling(data) {
    return api.post(`/xpcell-reports/billing`, data);
  }

  async getModels() {
    return api.post(`/xpcell-reports/models`);
  }

  async getPartsXModelsBOR(data) {
    return api.post(`/xpcell-reports/parts-x-models`, data);
  }

  async getComponentRegistration() {
    return api.post(`/xpcell-reports/component-registration`);
  }

  async getOutOfWarranty(data) {
    return api.post(`/xpcell-reports/out-of-warranty`, data);
  }

  async getParts(data) {
    return api.post(`/xpcell-reports/parts`, data);
  }

  async getPartsBPO(data) {
    return api.post(`/xpcell-reports/parts-bpo`, data);
  }

  async getPartsBPOTotal(data) {
    return api.post(`/xpcell-reports/parts-bpo-total`, data);
  }

  async getCancellationChargebacks(data) {
    return api.post(`/xpcell-reports/cancellation-of-chargebacks`, data);
  }

  async getCancellationQA(data) {
    return api.post(`/xpcell-reports/cancellation-QA`, data);
  }

  async getPnQueryArchived(data) {
    return api.post(`/xpcell-reports/pn-query-archived`, data);
  }

  async getQueryVoc(data) {
    return api.post(`/xpcell-reports/query-voc`, data);
  }

  async getPartsConsumption(data) {
    return api.post(`/xpcell-reports/parts-consumption`, data);
  }

  async getOsReported(data) {
    return api.post(`/xpcell-reports/getOsReported`, data);
  }

  async getBorWithoutPN(data) {
    return api.post(`/xpcell-reports/getBorWithoutPN`, data);
  }

  async getTechnicalStock(data) {
    return api.post(`/xpcell-reports/getTechnicalStock`, data);
  }

  async getHistoricStockPosition(data) {
    return api.post(`/xpcell-reports/getHistoricStockPosition`, data);
  }

  async getStockPosition() {
    return api.post(`/xpcell-reports/getStockPosition`);
  }

  async getPositionEntryNotes(data) {
    return api.post(`/xpcell-reports/getPositionEntryNotes`, data);
  }

  async getAgingPllWithParts() {
    return api.post(`/xpcell-reports/getAgingPllWithParts`);
  }

  async getInOutPLL(data) {
    return api.post(`/xpcell-reports/getInOutPLL`, data);
  }

  async getPartsPLL(data) {
    return api.post(`/xpcell-reports/getPartsPLL`, data);
  }

  async getFgConsumedParts(data) {
    return api.post(`/xpcell-reports/getFgConsumedParts`, data);
  }

  async getDeliveryConfirmation(data) {
    return api.post(`/xpcell-reports/getDeliveryConfirmation`, data);
  }

  async getNonCompliance(data) {
    return api.post(`/xpcell-reports/getNonCompliance`, data);
  }

  async getReceivingParts(data) {
    return api.post(`/xpcell-reports/getReceivingParts`, data);
  }

  async getSummaryCMP(data) {
    return api.post(`/xpcell-reports/getSummaryCMP`, data);
  }

  async getUnlockHistory(data) {
    return api.post(`/xpcell-reports/getUnlockHistory`, data);
  }
}

export const providersService = new ProvidersService();
