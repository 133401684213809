import React, { useCallback, useMemo } from 'react';
import { Switch } from 'react-router';

import Toolbar from '@material-ui/core/Toolbar';

import LayoutContextProvider from 'pages/Layout/context';
import Drawer from 'pages/Layout/Drawer';
import { Footer } from 'pages/Layout/Footer';
import { Header } from 'pages/Layout/Header';

import Home from 'pages/Private/Home';
import { AcessCodeCheck } from 'pages/Public/AcessCode';

import { AccessControlRoutes } from 'routes/roles/AccessControl';
import { AdministrationRoutes } from 'routes/roles/AdministrationRoutes';
import { CashierRoutes } from 'routes/roles/CashierRoutes';
import { CollaboratorsRoutes } from 'routes/roles/CollaboratorsRoutes';
import { DashboardSamsungRoutes } from 'routes/roles/DashboardSamsungRoutes';
import { FinancialRoutes } from 'routes/roles/FinancialRoutes';
import { GspnRoutes } from 'routes/roles/GspnRoutes';
import { InvoicesRoutes } from 'routes/roles/InvoicesRoutes';
import { ReceivementRoutes } from 'routes/roles/ReceivementRoutes';
import { ReleasesRoutes } from 'routes/roles/ReleasesRoutes';
import { ReportRoutes } from 'routes/roles/ReportRoutes';
import { SacRoutes } from 'routes/roles/SacRoutes';
import { ServiceOrderRoutes } from 'routes/roles/ServiceOrderRoutes';
import { StockRoutes } from 'routes/roles/StockRoutes';
import { Route } from 'routes/Route';

import { useStyles } from './styles';

const Private = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prevState) => !prevState);
  }, []);

  const contextValue = useMemo(
    () => ({
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    }),
    [
      anchorEl,
      setAnchorEl,
      mobileMoreAnchorEl,
      setMobileMoreAnchorEl,
      menuId,
      mobileMenuId,
      drawerOpen,
      handleDrawerToggle,
    ],
  );

  return (
    <LayoutContextProvider value={contextValue}>
      <div className={classes.root}>
        <Header />
        <Drawer />
        <main className={classes.main}>
          <div className={classes.content}>
            <Toolbar />
            <Switch>
              <Route isPrivate path='/code_acess' component={AcessCodeCheck} />
              <Route isPrivate path='/home' component={Home} />
              <Route isPrivate path='/reports' component={ReportRoutes} />
              <Route isPrivate path='/cashier' component={CashierRoutes} />
              <Route isPrivate path='/stock' component={StockRoutes} />
              <Route isPrivate path='/access-control' component={AccessControlRoutes} />
              <Route isPrivate path='/service-order' component={ServiceOrderRoutes} />
              <Route isPrivate path='/gspn' component={GspnRoutes} />
              <Route isPrivate path='/financial' component={FinancialRoutes} />
              <Route isPrivate path='/invoices' component={InvoicesRoutes} />
              <Route isPrivate path='/sac' component={SacRoutes} />
              <Route isPrivate path='/receivement' component={ReceivementRoutes} />
              <Route isPrivate path='/collaborators' component={CollaboratorsRoutes} />
              <Route isPrivate path='/administration' component={AdministrationRoutes} />
              <Route isPrivate path='/releases' component={ReleasesRoutes} />
              <Route isPrivate path='/dashboard-samsung' component={DashboardSamsungRoutes} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </LayoutContextProvider>
  );
};

export default Private;
