import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

export const StockPosition = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getStockPosition();

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { StockPosition: worksheet },
          SheetNames: ['StockPosition'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Posicao_Estoque_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.log('erro: ', error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Posição Estoque</PageTitle>

      <Formik initialValues={{}} onSubmit={handleDownloadReport}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
