import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

const typeSearch = [
  { label: 'Por Etapa', value: 'etapa' },
  { label: 'Por Técnico', value: 'tecnico' },
];

const typeProduct = [
  { label: 'Notebook', value: '15' },
  { label: 'Celular', value: '2' },
  { label: 'Tablet', value: '5' },
];

const typeProcess = [
  { label: 'Todos', value: 'todos' },
  { label: 'Refursbish', value: '2' },
  { label: 'Sem Refursbish', value: '1' },
  { label: 'RED', value: '6' },
];

const columnsByTechnician = [
  { label: 'numTecnico', key: 'numTecnico' },
  { label: 'nomeTecnico', key: 'nomeTecnico' },
  { label: '0-1', key: '0-1' },
  { label: '1-2', key: '1-2' },
  { label: '2-3', key: '2-3' },
  { label: '3-4', key: '3-4' },
  { label: '4-5', key: '4-5' },
  { label: '5-6', key: '5-6' },
  { label: '6-7', key: '6-7' },
  { label: '7-10', key: '7-10' },
  { label: '10-15', key: '10-15' },
  { label: '15-20', key: '15-20' },
  { label: '>20', key: '>20' },
  { label: 'total', key: 'total' },
];

const columnsByStage = [
  { label: 'Etapa', key: 'etapa' },
  { label: '3-4', key: '3-4' },
  { label: '4-5', key: '4-5' },
  { label: '5-6', key: '5-6' },
  { label: '6-7', key: '6-7' },
  { label: '7-10', key: '7-10' },
  { label: '10-15', key: '10-15' },
  { label: '15-20', key: '15-20' },
  { label: '>20', key: '>20' },
  { label: 'total', key: 'total' },
];

export const Aging4hrs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(columnsByTechnician); // Inicia com colunas por técnico

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, A tabela está sendo gerada');
    try {
      const { data } = await providersService.getAging4hrs({
        type_search: values.type_search,
        type_product: values.type_product,
        type_process: values.type_process,
        checkboxes: values.checkboxes,
      });

      setReportData(data);
      toast.success('Dados carregados com sucesso!');

      // Define as colunas com base no tipo de pesquisa selecionado
      if (values.type_search === 'etapa') {
        setSelectedColumns(columnsByStage);
      } else {
        setSelectedColumns(columnsByTechnician);
      }
    } catch (error) {
      console.error('Erro ao carregar os dados.');
      toast.error('Erro ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderTableRows = () => {
    if (!reportData) return null;

    return reportData.map((item, index) => {
      if (selectedColumns === columnsByTechnician) {
        return (
          <TableRow key={index}>
            <TableCell>{item.numTecnico}</TableCell>
            <TableCell>{item.nomeTecnico}</TableCell>
            <TableCell>{item['0-1']}</TableCell>
            <TableCell>{item['1-2']}</TableCell>
            <TableCell>{item['2-3']}</TableCell>
            <TableCell>{item['3-4']}</TableCell>
            <TableCell>{item['4-5']}</TableCell>
            <TableCell>{item['5-6']}</TableCell>
            <TableCell>{item['6-7']}</TableCell>
            <TableCell>{item['7-10']}</TableCell>
            <TableCell>{item['10-15']}</TableCell>
            <TableCell>{item['15-20']}</TableCell>
            <TableCell>{item['>20']}</TableCell>
            <TableCell>{item.total}</TableCell>
          </TableRow>
        );
      } else if (selectedColumns === columnsByStage) {
        return (
          <TableRow key={index}>
            <TableCell>{item.etapa}</TableCell>
            <TableCell>{item['3-4']}</TableCell>
            <TableCell>{item['4-5']}</TableCell>
            <TableCell>{item['5-6']}</TableCell>
            <TableCell>{item['6-7']}</TableCell>
            <TableCell>{item['7-10']}</TableCell>
            <TableCell>{item['10-15']}</TableCell>
            <TableCell>{item['15-20']}</TableCell>
            <TableCell>{item['>20']}</TableCell>
            <TableCell>{item.total}</TableCell>
          </TableRow>
        );
      }
      return null;
    });
  };

  return (
    <>
      <PageTitle>Relatório Aging 4hrs</PageTitle>

      <Formik
        initialValues={{
          type_search: '',
          type_product: [],
          type_process: '',
          checkboxes: [],
        }}
        onSubmit={handleDownloadReport}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_search'
                  label='Tipo de Pesquisa'
                  variant='outlined'
                  options={typeSearch}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_product'
                  label='Produto'
                  variant='outlined'
                  options={typeProduct}
                  component={Select}
                  multiple
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_process'
                  label='Tipo de Processo'
                  variant='outlined'
                  options={typeProcess}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={6} container spacing={1}>
                {['LP', 'OW'].map((label, index) => (
                  <Grid item key={index}>
                    <FormControlLabel
                      control={
                        <Field
                          type='checkbox'
                          name='checkboxes'
                          as={Checkbox}
                          value={label}
                          checked={values.checkboxes.includes(label)}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
                <Grid item xs={4} lg={4}>
                  <Button type='submit' fullWidth loading={isLoading}>
                    Gerar Tabela
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12}>
            <Table name='Table' headers={selectedColumns} maxHeight disableNumeration>
              {renderTableRows()}
            </Table>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
