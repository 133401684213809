import { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const typeFinalidade = [
  { label: 'Todos', value: '0' },
  { label: 'Peças para Processo de Recondicionamento', value: '5' },
  { label: 'Peças para Reparo de Refurbish', value: '4' },
  { label: 'Peças Samsung', value: '2' },
  { label: 'Recebimento de Ferramentas - Engenharia', value: '3' },
];

export const PositionEntryNotes = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getPositionEntryNotes({
        input_data: values.input_data,
        output_data: values.output_data,
        type_finalidade: values.type_finalidade,
        only_balance: values.only_balance ? '0' : '',
        only_moved: values.only_moved ? '1' : '',
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { PositionEntryNotes: worksheet },
          SheetNames: ['PositionEntryNotes'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Posição_Notas_Entrada_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.log('erro: ', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Posição Notas Entrada</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
          type_finalidade: '',
          only_balance: false,
          only_moved: false,
        }}
        onSubmit={handleDownloadReport}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data cadastro NF'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data cadastro NF'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_finalidade'
                  label='Finalidade'
                  options={typeFinalidade}
                  variant='outlined'
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.only_balance}
                      onChange={() => setFieldValue('only_balance', !values.only_balance)}
                      color='primary'
                      disabled={isLoading}
                    />
                  }
                  label="Somente NF's com Saldo"
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.only_moved}
                      onChange={() => setFieldValue('only_moved', !values.only_moved)}
                      color='primary'
                      disabled={isLoading}
                    />
                  }
                  label="Somente NF's movimentadas"
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
