import React from 'react';
import { useRouteMatch } from 'react-router';

export const CashierRoutes = () => {
  const { url } = useRouteMatch();

  const { path } = useRouteMatch();

  return <></>;
};
