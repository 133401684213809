import { useRouteMatch } from 'react-router';

import { Closures } from 'pages/Private/Administration/Closures';
import { Route } from 'routes/Route';

export const AdministrationRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/closures`} component={Closures} />
    </>
  );
};
