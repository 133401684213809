import { Fragment, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FormatMoney, Spacer } from 'components';

import { useStyles } from './styles';

const columns = [
  { label: 'Nota fiscal', align: 'center' },
  { label: 'Chave', align: 'center' },
  { label: 'Natureza da operação', align: 'center' },
  { label: 'Fornecedor', align: 'center' },
  { label: 'CNPJ do fornecedor', align: 'center' },
  { label: 'Quantidade de produtos', align: 'center' },
  { label: 'Valor total', align: 'center' },
];

const subColumns = [
  { label: 'Item', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'NCM', align: 'center' },
  { label: 'CFOP', align: 'center' },
  { label: 'Valor', align: 'center' },
];

const mockData = [
  {
    id: 1,
    invoice: '145076',
    nf_key: '35240501166372001127550030001450761439915493',
    operation: 'Venda de Merc Adqrs Terceiros - Subst Tributaria',
    supplier: 'LG ELECTRONICS DO BRASIL LTDA',
    supplier_cgc: '01166372001127',
    amount: 7,
    value: 8851.4,
  },
];

const mockItens = [
  {
    id: 1,
    cProd: 'CV5014MC4A.AMBGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV5014MC4A,COM CAPACIDADE DE 14KG ROUPA SECA,220V,TITANIUM,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 418.11,
  },
  {
    id: 2,
    cProd: 'CV5014PC4A.APTGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV5014PC4A,COM CAPACIDADE DE 14KG ROUPA SECA,220V,PLATINUM,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 1150.92,
  },
  {
    id: 3,
    cProd: 'CV9014BC2.ABLFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPAS TIPO LAVA E SECA,MODELO LG:CV9014BC2,COM CAPACIDADE DE 14KG DE ROUPA SECA,127V,PRETO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 1354.13,
  },
  {
    id: 4,
    cProd: 'FV3011WG4.ABWFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV3011WG4,COM CAPACIDADE DE 11KG DE ROUPA SECA,127V,BRANCO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 555.45,
  },
  {
    id: 5,
    cProd: 'FV3011WG4A.ABWGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV3011WG4A,COM CAPACIDADE DE 11KG DE ROUPA SECA,220V,BRANCO,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 563.85,
  },
  {
    id: 6,
    cProd: 'FV5013WC4.ABWFBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV5013WC4,COM CAPACIDADE DE 13KG DE ROUPA SECA,127V,BRANCA,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 757.57,
  },
  {
    id: 7,
    cProd: 'FV5013WC4A.ABWGBRS',
    xProd:
      'MAQUINA DE LAVAR ROUPASLAVADORA,MODELO LG:FV5013WC4A,COM CAPACIDADE DE 13KG DE ROUPA SECA,220V,BRANCA,COM SELO A',
    amount: 1,
    ncm: '84502020',
    cfop: '5403',
    value: 747.36,
  },
];

export const ReceivingProductsTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleExpand = (rowId) => {
    setExpandedRowId((prevExpandedRowId) => (prevExpandedRowId === rowId ? null : rowId));
  };

  return (
    <>
      <Spacer size={40} />
      <Paper elevation={2}>
        <TableContainer>
          <Table aria-label='customized table'>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell />
                {columns.map((column) => (
                  <TableCell key={column.label} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mockData.map((item) => (
                <Fragment key={item.id}>
                  <TableRow>
                    <TableCell align='center'>
                      <IconButton onClick={() => handleToggleExpand(item?.id)}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>{item?.invoice}</TableCell>
                    <TableCell align='center'>{item?.nf_key}</TableCell>
                    <TableCell align='center'>{item?.operation}</TableCell>
                    <TableCell align='center'>{item?.supplier}</TableCell>
                    <TableCell align='center'>{item?.supplier_cgc}</TableCell>
                    <TableCell align='center'>{item?.amount}</TableCell>
                    <TableCell align='center'>
                      <FormatMoney>{item?.value}</FormatMoney>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedRowId === item?.id} timeout='auto' unmountOnExit>
                        <Typography variant='h6' gutterBottom component='div'>
                          Itens
                        </Typography>
                        <Table size='small' aria-label='purchases'>
                          <TableHead className={classes.head}>
                            <TableRow>
                              {subColumns.map((column) => (
                                <TableCell key={column.label} align={column.align}>
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mockItens.map((sub_item) => (
                              <TableRow key={sub_item.id}>
                                <TableCell align='center'>{sub_item.id}</TableCell>
                                <TableCell align='center'>{sub_item.cProd}</TableCell>
                                <TableCell align='center'>{sub_item.xProd}</TableCell>
                                <TableCell align='center'>{sub_item.amount}</TableCell>
                                <TableCell align='center'>{sub_item.ncm}</TableCell>
                                <TableCell align='center'>{sub_item.cfop}</TableCell>
                                <TableCell align='center'>
                                  <FormatMoney>{sub_item?.value}</FormatMoney>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 75, 100, 200]}
          component='div'
          count={1}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage='Linhas por página:'
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : ''}`
          }
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
