import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import DownloadingIcon from '@mui/icons-material/Downloading';
import { IconButton } from '@mui/material';
import { FormatDate, FormatMoney, Select, Spacer, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoicesService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const invoice_options = [
  {
    label: 'Kit novo',
    value: 1,
  },
  {
    label: 'Devolução de peças',
    value: 2,
  },
];

const columns = [
  { label: 'NF', align: 'center' },
  { label: 'Série', align: 'center' },
  { label: 'Chave NF', align: 'center' },
  { label: 'Valor', align: 'center' },
  { label: 'Data emissão', align: 'center' },
  { label: 'Link', align: 'center' },
  { label: 'XML', align: 'center' },
];

export const SearchInvoices = () => {
  const classes = useStyles();

  const [getInvoiceType, setInvoiceType] = useState(1);
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [getInvoice, loadingInvoice, refetchInvoice] = useQuery(
    () => invoicesService.searchInvoice(startIndex, rowsPerPage, search.trim(), getInvoiceType),
    [startIndex, rowsPerPage, search, getInvoiceType],
  );

  console.log('getInvoice', getInvoice);

  const handleChangePage = (event, newPage) => {
    console.log('newPage', newPage);
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setPage(0);
    setStartIndex(1);
    const v = e.target.value;
    setSearch(v);
  };

  const handleDownload = (link) => {
    window.open(`${link}`, '_blank');
  };

  const handleDownloadXml = async (id) => {
    try {
      const { data } = await invoicesService.getXmlInvoice(id);

      const blob = new Blob([data], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `XML_${id}.xml`;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toast.success('XML baixado com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao baixar o XML.');
    }
  };

  return (
    <>
      <PageTitle>Consultar notas fiscais</PageTitle>
      <Formik initialValues={{ invoice: '', invoice_type: 1 }} validationSchema={validationSchema}>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Tipo de nota fiscal'
                    name='invoice_type'
                    size='small'
                    options={invoice_options}
                    onChange={(e) => setInvoiceType(e)}
                    component={Select}
                  />
                </Grid>
              </Grid>{' '}
              <Spacer size={35} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    name='invoice'
                    fullWidth
                    endAdornment={<SearchIcon />}
                    label='Pesquisar nota fiscal'
                    component={Text}
                    onChange={handleSearchChange}
                  />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>

      <Spacer size={20} />
      <Table
        disableNumeration
        headers={columns}
        striped
        emptyMessage='Nenhum registro encontrado.'
        data={getInvoice?.data}
        loading={loadingInvoice}
      >
        {getInvoice?.data?.map((item, index) => (
          <TableRow key={index}>
            <TableCell align='center'>{item?.invoice_number}</TableCell>
            <TableCell align='center'>{item?.serie}</TableCell>
            <TableCell align='center'>{item?.nfse_number}</TableCell>
            <TableCell align='center'>
              <FormatMoney>{item?.price}</FormatMoney>
            </TableCell>
            <TableCell align='center'>
              <FormatDate>{item?.created_at}</FormatDate>
            </TableCell>
            <TableCell align='center'>
              {' '}
              <IconButton onClick={() => handleDownload(item?.link_invoice)}>
                <DownloadingIcon
                  fontSize='large'
                  sx={{
                    color: !item?.link_invoice ? 'grey' : '#311b92',
                  }}
                />
              </IconButton>
            </TableCell>
            <TableCell align='center'>
              {' '}
              <IconButton onClick={() => handleDownloadXml(item?.tiny_id)}>
                <DownloadingIcon
                  fontSize='large'
                  sx={{ color: !item?.invoice_number ? 'grey' : '#311b92' }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 50, 75, 100, 200]}
        component='div'
        count={getInvoice?.totalRows}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Linhas por página:'
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : ''}`
        }
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* <Grid className={classes.containerImg} container>
        <InvoicePrint className={classes.trashImage} />
      </Grid> */}
    </>
  );
};
