import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  service_order: yup.string().required(),
  client_cod: yup.string().required(),
  invoice: yup
    .string()
    .matches(/^[1-9][0-9]*$/, 'A nota fiscal não pode começar com 0')
    .required(),
  product: yup.string().required(),
  imei: yup.string().required(),
});
