import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { releasesService } from '../../../../../services/releases';

const validationSchema = yup.object().shape({
  input_obs: yup.string().required('Observação é obrigatório'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, infoOs }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const newMsg = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await releasesService.getNewMsgBlock({
        idBloqueio: props.values.idBloqueio,
        obs: props.values.input_obs,
        usuario: user.name,
      });
      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar');
    } finally {
      setIsLoading(false);
      handleCloseDialogEdit();
    }
  };

  return (
    <Dialog open={openDialogEdit}>
      <Dialog.Title onClose={handleCloseDialogEdit}>Alterar Mensagem</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            idBloqueio: infoOs.idBloqueio,
            input_obs: '',
            usuario: user.name,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                    ID Bloqueio
                  </Typography>
                  <Typography variant='h6' align='center' name='idBloqueio'>
                    {infoOs.idBloqueio}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    size='small'
                    name='input_obs'
                    variant='outlined'
                    label='Alterar Observação'
                    component={Text}
                    multiline
                    minRows={8}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    loading={isLoading}
                    onClick={() => newMsg(props)}
                  >
                    Alterar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseDialogEdit}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
