import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';
import * as Yup from 'yup';

const typeSelect = [
  { label: 'Entrada', value: 'entrada' },
  { label: 'Saida', value: 'saida' },
];

const typeDate = [
  { label: 'Data de Recebimento', value: 'dtEntrada' },
  { label: 'Data de Abertura', value: 'dtCadastro' },
];

const typeDateSaida = [
  { label: 'Data de Faturamento', value: 'dtFaturamento' },
  { label: 'Data Minuta', value: 'dataMinuta' },
  { label: 'Data Checkkit', value: 'dtCheckkit' },
  { label: 'Dt. Aprov CQ', value: 'dtAprvCQ' },
  { label: 'Dt. Garantia', value: 'dtGarantia' },
];

const validationSchema = Yup.object({
  type_report: Yup.string().required('Tipo do relatório é obrigatório'),
  type_data: Yup.string().required('Tipo do data é obrigatório'),
});

export const InOutReport = () => {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 7)));
  const [outputData, setOutputData] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async ({ values }) => {
    setIsLoading(true);
    console.log(values);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getInOutReport({
        type_report: values.type_report,
        type_data: values.type_data,
        type_data_saida: values.type_data_saida,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { InOutReport: worksheet },
          SheetNames: ['InOutReport'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_IN_OUT_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório IN OUT</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          type_data: '',
          type_data_saida: '',
          input_data: inputData,
          output_data: outputData,
        }}
        validationSchema={validationSchema}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_report'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              {props.values.type_report === 'entrada' && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='type_data'
                      label='Tipo do data'
                      variant='outlined'
                      options={typeDate}
                      component={Select}
                    />
                  </Grid>
                </>
              )}
              {props.values.type_report === 'saida' && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='type_data_saida'
                      label='Tipo do data'
                      variant='outlined'
                      options={typeDateSaida}
                      component={Select}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button fullWidth loading={isLoading} onClick={() => handleDownloadReport(props)}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
