/* eslint-disable import-helpers/order-imports */
/* eslint-disable no-restricted-imports */
import { useState } from 'react';
import Slider from 'react-slick';

import { Grid, IconButton, Paper, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormatMoney, Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ClosuresDialog } from './ClosuresDialog';

import { PageTitle } from 'pages/Layout/PageTitle';

const columns = [
  { label: 'ID', align: 'center' },
  { label: 'Tipo de fechamento', align: 'center' },
  { label: 'Mês', align: 'center' },
  { label: 'Usuário', align: 'center' },
  { label: 'Total', align: 'center' },
  { label: 'Ver mais', align: 'center' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export const ClosuresTable = ({ data, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [getData, setDate] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setDate(item);
  };

  const forecastDataMap = data.reduce((map, item) => {
    item.itens.forEach((i) => {
      const date = i.payment_prevision;
      if (!map[date]) {
        map[date] = 0;
      }
      map[date] += i.value;
    });
    return map;
  }, {});

  const forecastData = Object.keys(forecastDataMap).map((date) => ({
    date,
    'Ganhos estimado': forecastDataMap[date],
    value: forecastDataMap[date],
  }));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageTitle>Fechamentos</PageTitle>
              <Table
                headers={columns}
                loading={loading}
                emptyMessage='Nenhum registro encontrado.'
                striped
                disableNumeration
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {data?.map((object, index) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{object?.id_header}</TableCell>
                    <TableCell align='center'>{object?.closing_type}</TableCell>
                    <TableCell align='center'>{object?.closing_month}</TableCell>
                    <TableCell align='center'>{object?.user_create}</TableCell>
                    <TableCell align='center'>
                      {' '}
                      <FormatMoney>
                        {object?.itens?.reduce(
                          (acc, component) => (acc += Number(component?.value ?? 0)),
                          0,
                        )}
                      </FormatMoney>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton color='primary' onClick={(e) => handleOpenInformation(e, object)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
          </Grid>
          <ClosuresDialog
            data={getData}
            open={isDetailsOpen}
            handleClose={handleCloseInformation}
          />
        </div>
        <div>
          <PageTitle>Overview</PageTitle>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <h2>Total de Ganhos</h2>
                <FormatMoney>
                  {data.reduce(
                    (acc, item) => acc + item.itens.reduce((sum, i) => sum + i.value, 0),
                    0,
                  )}
                </FormatMoney>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <h2>Quantidade de itens Processadas</h2>
                {data.reduce((acc, item) => acc + item.itens.length, 0)}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <h2>Valor Médio</h2>
                <FormatMoney>
                  {data.reduce(
                    (acc, item) => acc + item.itens.reduce((sum, i) => sum + i.value, 0),
                    0,
                  ) / data.reduce((acc, item) => acc + item.itens.length, 0)}
                </FormatMoney>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div>
          <PageTitle>Previsão de Pagamentos</PageTitle>
          <ResponsiveContainer width='100%' height={400}>
            <LineChart
              width={500}
              height={300}
              data={forecastData}
              desc='Previsão de Pagamentos'
              title='Previsão de Pagamentos'
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type='monotone'
                dataKey='Ganhos estimado'
                stroke='#8884d8'
                activeDot={{ r: 8 }}
                label={{
                  position: 'top',
                  formatter: (value) =>
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                      value,
                    ),
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Slider>
    </>
  );
};
