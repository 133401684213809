import { api } from 'services/api';

export class InvoicesService {
  async searchInvoice(startIndex, rowsPerPage, search, invoice_type) {
    return await api.post('/invoice/search/invoice', {
      startIndex,
      rowsPerPage,
      search,
      invoice_type,
    });
  }

  async getXmlInvoice(tiny_id) {
    return await api.get(`/invoice/search/xml/${tiny_id}`);
  }
}

export const invoicesService = new InvoicesService();
