import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Dialog, FormatMoney, Table, Text } from 'components';

const columns = [
  { label: 'Produto', align: 'center' },
  { label: 'NF serviço', align: 'center' },
  { label: 'NF Venda', align: 'center' },
  { label: 'Previsão de pagamento', align: 'center' },
  { label: 'Valor', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Total', align: 'center' },
  { label: 'Observação', align: 'center' },
];

export const ClosuresDialog = ({ data, open, handleClose }) => {
  if (!open) return null;
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    setSearch(value);
  };

  const formattedData = data?.itens.map((b) => {
    return {
      ...b,
    };
  });

  const filteredData = formattedData.filter((filter) => {
    console.log('filter', filter);

    const upperService =
      typeof String(filter.reference) === 'string' ? String(filter.reference).toUpperCase() : '';
    const lowerService =
      typeof String(filter.reference) === 'string' ? String(filter.reference).toLowerCase() : '';

    const upperInvoiceSeller =
      typeof filter.invoice_seller === 'string' ? filter.invoice_seller.toUpperCase() : '';
    const lowerInvoiceSeller =
      typeof filter.invoice_seller === 'string' ? filter.invoice_seller.toLowerCase() : '';

    const upperInvoiceService =
      typeof filter.invoice_service === 'string' ? filter.invoice_service.toUpperCase() : '';
    const lowerInvoiceService =
      typeof filter.invoice_service === 'string' ? filter.invoice_service.toLowerCase() : '';

    const upperObsService =
      typeof filter.observation === 'string' ? filter.observation.toUpperCase() : '';
    const lowerObsService =
      typeof filter.observation === 'string' ? filter.observation.toLowerCase() : '';

    const searchTerms = search.toLowerCase().split(' ');

    return searchTerms.every(
      (term) =>
        upperService.includes(term) ||
        lowerService.includes(term) ||
        upperInvoiceSeller.includes(term) ||
        lowerInvoiceSeller.includes(term) ||
        upperInvoiceService.includes(term) ||
        lowerInvoiceService.includes(term) ||
        upperObsService.includes(term) ||
        lowerObsService.includes(term),
    );
  });

  return (
    <>
      <Dialog
        maxWidth='md'
        scroll='paper'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <Dialog.Title>Informações Adicionais</Dialog.Title>
        <Dialog.Content>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5, marginTop: 1 }}>
                Tipo de fechamento:
              </p>
              <p style={{ fontWeight: '300', fontSize: 16 }}>{data.closing_type}</p>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5, marginTop: 1 }}>
                Mês:
              </p>
              <p style={{ fontWeight: '300', fontSize: 16 }}>{data.closing_month}</p>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5, marginTop: 1 }}>
                Usuário:
              </p>
              <p style={{ fontWeight: '300', fontSize: 16 }}>{data.user_create}</p>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 5, marginTop: 1 }}>
                Total:
              </p>
              <p style={{ fontWeight: '300', fontSize: 16 }}>
                {' '}
                <FormatMoney>
                  {data?.itens?.reduce(
                    (acc, component) =>
                      acc + Number(component?.value ?? 0) * Number(component?.amount ?? 0) ?? 0,
                    0,
                  )}
                </FormatMoney>
              </p>
            </Grid>
          </Grid>
        </Dialog.Content>

        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Text
                label='Pesquisar'
                size='small'
                variant='outlined'
                endAdornment={<SearchIcon />}
                value={search}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                headers={columns}
                data={filteredData}
                emptyMessage='Nenhum registro encontrado.'
                striped
                disableNumeration
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {filteredData?.map((component, index) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{component?.reference}</TableCell>
                    <TableCell align='center'>{component?.invoice_service}</TableCell>
                    <TableCell align='center'>{component?.invoice_seller}</TableCell>
                    <TableCell align='center'>{component?.payment_prevision}</TableCell>
                    <TableCell align='center'>
                      <FormatMoney>{component?.value ?? 0}</FormatMoney>
                    </TableCell>
                    <TableCell align='center'>{component?.amount}</TableCell>
                    <TableCell align='center'>
                      <FormatMoney>
                        {(component?.value ?? 0) * (component?.amount ?? 0)}
                      </FormatMoney>
                    </TableCell>
                    <TableCell align='center'>{component?.observation}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' spacing={-5}>
            <Grid>{}</Grid>
            <Grid style={{ fontSize: 24, fontWeight: 'bold' }}>
              Total:{' '}
              <FormatMoney>
                {filteredData?.reduce(
                  (acc, component) =>
                    acc + Number(component?.value ?? 0) * Number(component?.amount ?? 0) ?? 0,
                  0,
                )}
              </FormatMoney>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
