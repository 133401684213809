import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { readCsv } from '../../../../helpers/readcsv';

const typeSelect = [
  { label: 'Período', value: '0' },
  { label: 'Numero OS', value: '1' },
];

export const OutOfWarranty = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadCSV, setIsLoadCSV] = useState([]);
  const [isCSVValid, setIsCSVValid] = useState(true);
  const fileInputRef = useRef(null);

  const handleDownloadReport = async (values) => {
    if (isLoadCSV.length === 0 && values.type_report === '1') {
      toast.error('Por favor, importe um arquivo antes de exportar o relatório.');
      return;
    }
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getOutOfWarranty({
        type_report: values.type_report,
        input_data: values.input_data,
        output_data: values.output_data,
        numOs: isLoadCSV,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { OutOfWarranty: worksheet },
          SheetNames: ['OutOfWarranty'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Fora_Garantia_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileInputChange = async (event) => {
    try {
      // Lê o arquivo CSV usando a função `readCsv` e aguarda sua conclusão
      const data_csv = await readCsv(event);

      if (!data_csv) {
        toast.error('O arquivo CSV não pode ser lido.');
      }

      // Verifica se o número de linhas excede 2000
      if (data_csv.length > 2000) {
        toast.error('O arquivo não pode conter mais de 2000 linhas.');
        setIsCSVValid(false);
        return;
      }

      // Define os dados lidos no estado `isLoadCSV
      setIsLoadCSV(data_csv);
      setIsCSVValid(true);
      toast.success('Arquivo lido com sucesso!');
    } catch (error) {
      console.error('Erro ao ler o arquivo: ' + error.message);
      setIsCSVValid(false);
    }
  };

  const handleUploadFileClick = () => {
    // Abre a pasta para o upload de arquivo
    fileInputRef.current.click();
  };

  return (
    <>
      <PageTitle>Relatório Fora de Garantia</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleDownloadReport}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_report'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              {values.type_report === '0' && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              {values.type_report === '1' && (
                <Grid item xs={12} lg={2}>
                  <label htmlFor='file-upload' style={{ display: 'block' }}>
                    <Button
                      fullWidth
                      variant='outlined'
                      style={{ backgroundColor: '#f50057', color: '#fff' }}
                      onClick={handleUploadFileClick}
                    >
                      Importar arquivo
                    </Button>
                  </label>
                  <input
                    id='file-upload'
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={2}>
                <Button
                  type='submit'
                  fullWidth
                  loading={isLoading}
                  disabled={!isCSVValid || isLoading}
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OutOfWarranty;
