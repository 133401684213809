import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/undraw_credit_card_payment_re_o911.svg';
import { Button, Select } from 'components';
import { saveAs } from 'file-saver';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { xpcellService } from 'services';
import XLSX from 'xlsx';
import * as yup from 'yup';

import { DialogInformationForImport } from './DialogInformationForImport';
import { FinancialCloseTable } from './financialCloseTable';
import { FinancialCloseTableAcc } from './financialCloseTableAcc';

export const validationSchema = yup.object().shape({
  closing_types: yup.string().required(),
});

export const FinancialClose = () => {
  const [importing, setImporting] = useBoolean(false);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [getResults, setResults] = useState();

  const [getClosingTypes, , loadingClosingTypes, refetchClosingTypes] = useQuery(
    () => xpcellService.getClosingTypes(),
    [],
  );

  const closingTypesOptions = (getClosingTypes || []).map((status) => {
    return {
      id: status.id,
      value: status.descricao,
      label: status.descricao,
    };
  });

  const inputFileRef = useRef(null);
  const formikRef = useRef();

  const handleOpenInformation = () => {
    setIsDetailsState.toTrue();
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleDownloadLayoutDevolutionInvoices = async () => {
    const wb = XLSX.utils.book_new();

    const data = [
      {
        a: 'Referência (numero da OS, partnumber, aparelho store +)',
        b: 'NF de serviço (exemplo: 25050)',
        c: 'NF de venda (exemplo: 58212)',
        d: 'Valor (exemplo: 25,5)',
        e: 'Quantidade',
        f: 'Previsão de pagamento (exemplo: 25/04/2024)',
        g: 'Observação',
      },
    ];

    const ws = XLSX.utils.aoa_to_sheet(data.map(Object.values).map((row) => row.map(String)));

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Layout_fechamento.xlsx');
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (e) => {
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getDevolutions = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              reference: obj.split(';')[0],
              nf_service: obj.split(';')[1],
              nf_seller: obj.split(';')[2],
              value: parseFloat(obj.split(';')[3]?.replace(',', '.')).toFixed(2),
              amount: obj.split(';')[4]?.replace(',', '.'),
              payment_prevision: obj.split(';')[5],
              observation: obj.split(';')[6],
              closing_types: formikRef.current?.values?.closing_types,
            };
            return dados;
          }
        });

        const serviceFilter = getDevolutions.filter((item) => item !== false);

        try {
          const { data } = await xpcellService.validatedServiceOrderForFinancialClosing({
            transmission_data: serviceFilter,
          });

          if (data.length != 0) {
            toast.success(`${data.length} services importadas com sucesso`);
            setResults(data);
            handleCloseInformation();
            inputFileRef.current.value = '';
          } else toast.error('Ocorreu um erro ao realizar a importação das services');
        } catch (error) {
          toast.error(
            error?.response?.data?.message || 'Erro ao realizar a importação do arquivo.',
          );
        } finally {
          setImporting.toFalse();
        }
      };

      reader.readAsText(file);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleReset = () => {
    setResults();
    formikRef?.current?.resetForm();
    inputFileRef.current.value = '';
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => [setImporting.toTrue(), handleFileChange(e)]}
        accept='text/csv'
        hidden
      />

      <PageTitle dev>Fechamento financeiro</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ operation: '' }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Paper variant='elevation'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name='closing_types'
                          label='Tipo de fechamento'
                          variant='outlined'
                          size='small'
                          component={Select}
                          disabled={importing}
                          options={closingTypesOptions}
                        />
                      </Grid>
                      {importing ? (
                        <Grid xs={6} lg={12}>
                          <Box sx={{ width: '90%' }}>
                            <LinearProgress color='secondary' />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid xs={6} lg={12} style={{ display: 'flex', margin: 'auto' }}>
                          <UploadImg style={{ height: 200, marginTop: 15 }} />
                        </Grid>
                      )}
                      <Grid item xs={12} lg={3}>
                        <Button
                          fullWidth
                          onClick={() => handleOpenInformation()}
                          type=''
                          disabled={!props.isValid || !props.dirty}
                          loading={importing}
                          startIcon={<FileUploadIcon />}
                        >
                          IMPORTAR PLANILHA
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Button
                          fullWidth
                          variant='outlined'
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadLayoutDevolutionInvoices}
                          disabled={importing}
                        >
                          BAIXAR PLANILHA
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      {getResults &&
      (formikRef.current?.values?.closing_types == 'ACESSORIOS' ||
        formikRef.current?.values?.closing_types == 'STORE +') ? (
        <FinancialCloseTableAcc
          data={getResults}
          closingType={formikRef.current?.values?.closing_types}
          setLoading={setImporting}
          loading={importing}
          reset={handleReset}
          setResults={setResults}
        />
      ) : getResults ? (
        <FinancialCloseTable
          data={getResults}
          closingType={formikRef.current?.values?.closing_types}
          setLoading={setImporting}
          loading={importing}
          reset={handleReset}
          setResults={setResults}
        />
      ) : (
        <></>
      )}

      <DialogInformationForImport
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        importFile={handleImportClick}
      />
    </>
  );
};
