import { useRouteMatch } from 'react-router';

import { UploadImage } from 'pages/Private/DashboardSamsung/UploadImage';
import { ViewDashboard } from '../../pages/Private/DashBoardSamsung/ViewDashboard';

import { Route } from '../Route';

export const DashboardSamsungRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/upload-image`} component={UploadImage} />
      <Route isPrivate path={`${url}/view-dashboard`} component={ViewDashboard} />
    </>
  );
};
