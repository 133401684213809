import React, { useEffect, useState } from 'react';
import { uploadImagesDashService } from '../../../../services/uploadImagesDash';

export const ViewDashboard = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Função para buscar imagens do back-end
    const fetchImages = async () => {
      try {
        const response = await uploadImagesDashService.getImages();
        console.log('Resposta da API:', response);
        console.log('Imagens recebidas:', response.data);

        // Verifica se a resposta contém dados e é um array
        if (Array.isArray(response.data)) {
          setImages(response.data);
        } else {
          console.error('Dados recebidos não são um array:', response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar imagens:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (images.length === 0) return;

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // 10000 milissegundos = 10 segundos

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <>
      {images.length > 0 ? (
        <img
          src={`data:image/png;base64,${images[currentImageIndex].image}`}
          alt={`Imagem da Dashboard ${currentImageIndex + 1}`}
          className='dashboard-image'
          onError={(e) => {
            console.error('Erro ao carregar imagem:', e.target.src);
            // Opcional: Substitua por uma imagem padrão ou mensagem de erro
            e.target.src = '/path/to/default/image.png';
          }}
        />
      ) : (
        <p>Carregando imagens...</p>
      )}
    </>
  );
};
