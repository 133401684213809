import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const selectOption = [
  { label: 'LaudoQA', value: '0' },
  { label: 'DuploQA', value: '1' },
];

export const NonCompliance = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getNonCompliance({
        type_qa: values.type_qa,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { NonCompliance: worksheet },
          SheetNames: ['NonCompliance'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Não_Conformidade_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Não Conformidade QA</PageTitle>

      <Formik
        initialValues={{ input_data: new Date(), output_data: new Date(), type_qa: '' }}
        onSubmit={handleDownloadReport}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_qa'
                label='TIpo QA'
                variant='outlined'
                options={selectOption}
                component={Select}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
