import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, infoClient }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  // Função para separar tipo de logradouro e logradouro completo
  const splitEndereco = (endereco) => {
    const firstSpaceIndex = endereco.indexOf(' ');
    if (firstSpaceIndex !== -1) {
      const tipoLogradouro = endereco.substring(0, firstSpaceIndex);
      const logradouro = endereco.substring(firstSpaceIndex + 1);
      return [tipoLogradouro, logradouro];
    } else {
      return ['', endereco];
    }
  };

  // Inicializa tipoLogradouro e logradouro a partir do infoClient
  const [tipoLogradouro, logradouro] = infoClient.clienteEndereco
    ? splitEndereco(infoClient.clienteEndereco)
    : ['', ''];

  const updateClient = async (props) => {
    setIsLoading(true);

    try {
      const { data } = await providersService.updateClientResubissionCasesOpen({
        idTicket: props.values.idTicket,
        tipoLogradouro: props.values.input_type_logr,
        logradouro: props.values.input_logradouro,
        numero: props.values.input_numero,
        complemento: props.values.input_complemento,
        bairro: props.values.input_bairro,
        cep: props.values.input_cep,
        cidade: props.values.input_cidade,
        estado: props.values.input_uf,
        nome: props.values.input_nome,
        observacao: props.values.input_obs,
        usuario: user.name,
      });
      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar');
    } finally {
      setIsLoading(false);
      handleCloseDialogEdit();
    }
  };

  return (
    <Dialog maxWidth={'md'} open={openDialogEdit}>
      <Dialog.Title onClose={handleCloseDialogEdit}>Atualize os dados do Cliente</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            idTicket: infoClient.idTicket,
            input_type_logr: tipoLogradouro,
            input_logradouro: logradouro,
            input_numero: infoClient.clienteNumero,
            input_complemento: infoClient.clienteComplemento,
            input_bairro: infoClient.clienteBairro,
            input_cep: infoClient.clienteCep,
            input_cidade: infoClient.clienteCidade,
            input_uf: infoClient.clienteEstado,
            input_nome: infoClient.clienteNome,
            input_obs: infoClient.obs,
          }}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    name='input_type_logr'
                    label='Tipo Logradouro'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={8}>
                  <Field
                    size='small'
                    name='input_logradouro'
                    label='Logradouro'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    name='input_numero'
                    label='Numero'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={8}>
                  <Field
                    size='small'
                    name='input_complemento'
                    label='Complemento'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={8}>
                  <Field
                    size='small'
                    name='input_bairro'
                    label='Bairro'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    name='input_cep'
                    label='CEP'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={8}>
                  <Field
                    size='small'
                    name='input_cidade'
                    label='Cidade'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    name='input_uf'
                    label='Estado'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={12}>
                  <Field
                    size='small'
                    name='input_nome'
                    label='Nome'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={12}>
                  <Field
                    size='small'
                    name='input_obs'
                    label='Observação.'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    loading={isLoading}
                    onClick={() => updateClient(props)}
                  >
                    Atualizar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseDialogEdit}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
