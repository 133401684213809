import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const filter = [
  { label: 'Numero OS', value: '0' },
  { label: 'OS Parceiro', value: '1' },
];

export const QueryVoc = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providersService.getQueryVoc({
        type_report: values.type_report,
        input_os: values.input_os,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { QueryVoc: worksheet },
          SheetNames: ['QueryVoc'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Consulta_VOC_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Consulta VOC</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          input_os: '',
        }}
        onSubmit={handleDownloadReport}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_report'
                label='Tipo do relatório'
                variant='outlined'
                options={filter}
                component={Select}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                name='input_os'
                label='PartNumber'
                variant='outlined'
                component={Text}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
