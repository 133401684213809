import { api } from 'services/api';

export class ComponentService {
  async createDevolutionInvoice(data) {
    return api.post('/component/devolution/invoice', data);
  }

  async createDevolutionInvoiceUnclass(data) {
    return api.post('/component/devolution/invoiceUnclass', data);
  }
}

export const componentService = new ComponentService();
