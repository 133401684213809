import { useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Dialog } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do GAIA',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Deixe todos os campos como texto',
  },
  {
    value: 5,
    label: 'Separe as casas decimais com vírgula',
  },
  {
    value: 6,
    label:
      'O campo referência coloque o número da OS XPCELL ou PN (no caso de venda de acessórios) ou o aparelho (no caso do store +)',
  },
  {
    value: 7,
    label: 'Salve o arquivo como .csv',
  },
];

export const DialogInformationForImport = ({ open, handleClose, importFile }) => {
  if (!open) return null;

  const [disable, setDisable] = useState(true);
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          clearInterval(interval);
          setDisable(false);
        }
        return lastTimerCount - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title att onClose={handleClose}>
        <PageTitle att>Instruções</PageTitle>
      </Dialog.Title>
      <Dialog.Content>
        <Typography style={{ fontWeight: 'bold' }}>Siga as seguintes instruções:</Typography>
        <List>
          {examples.map((item) => (
            <ListItem key={item.value}>
              <ListItemIcon>
                <CheckIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Dialog.Content>
      <Dialog.Content>
        <Button fullWidth disabled={disable} onClick={() => importFile()}>
          Confirmar {timer <= 0 ? '' : timer}
        </Button>{' '}
      </Dialog.Content>
    </Dialog>
  );
};
