import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Button, DatePicker, Select, Spacer } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { xpcellService } from 'services';

import { ClosuresTable } from './ClosuresTable';
import { validationSchema } from './validation';

const months = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

export const Closures = () => {
  const [getResults, setResults] = useState();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    toast.info('Buscando fechamentos...');
    setSubmitting(true);
    try {
      values.closing_year = values.closing_year?.getFullYear();
      console.log(values);

      const { data } = await xpcellService.searchFinancialClosing(values);

      setResults(data);

      setSubmitting(false);
      resetForm();
      toast.success('Fechamento encontrado com sucesso');
    } catch (err) {
      setSubmitting(false);
      toast.error(err?.response?.data?.message || 'Erro ao buscar o fechamento.');
    }
  };

  return (
    <>
      <PageTitle>Fechamentos</PageTitle>
      <Formik
        initialValues={{
          closing_year: new Date(),
          month_start: '',
          month_end: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Paper variant='elevation'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <Field
                          name='closing_year'
                          label='Ano de fechamento'
                          component={DatePicker}
                          views={['year']}
                          format='yyyy'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Field
                          name='month_start'
                          label='Mês de Início'
                          variant='outlined'
                          size='small'
                          component={Select}
                          options={months}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Field
                          name='month_end'
                          label='Mês de Fim'
                          variant='outlined'
                          size='small'
                          component={Select}
                          options={months}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Button
                          fullWidth
                          type='submit'
                          loading={props.isSubmitting}
                          disabled={props.isSubmitting || !props.isValid || !props.dirty}
                        >
                          Pesquisar
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Spacer size={50} />
      {(getResults?.length >= 0) | !!getResults ? (
        <>
          <ClosuresTable data={getResults} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
