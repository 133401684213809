import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { readCsv } from '../../../../helpers/readcsv';

export const PartsBPOTotal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadCSV, setIsLoadCSV] = useState([]);
  const [isCSVValid, setIsCSVValid] = useState(true);
  const fileInputRef = useRef(null);

  const handleDownloadReport = async () => {
    if (isLoadCSV.length === 0) {
      toast.error('Por favor, importe um arquivo antes de exportar o relatório.');
      return;
    }
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getPartsBPOTotal({
        numOs: isLoadCSV,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { PartsBPOTotal: worksheet },
          SheetNames: ['PartsBPOTotal'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Peças_BPO_Total_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileInputChange = async (event) => {
    try {
      // Lê o arquivo CSV usando a função `readCsv` e aguarda sua conclusão
      const data_csv = await readCsv(event);

      if (!data_csv) {
        toast.error('O arquivo CSV não pode ser lido.');
      }

      // Verifica se o número de linhas excede 2000
      if (data_csv.length > 2000) {
        toast.error('O arquivo não pode conter mais de 2000 linhas.');
        setIsCSVValid(false);
        return;
      }

      // Define os dados lidos no estado `isLoadCSV
      setIsLoadCSV(data_csv);
      setIsCSVValid(true);
      toast.success('Arquivo lido com sucesso!');
    } catch (error) {
      console.error('Erro ao ler o arquivo: ' + error.message);
      setIsCSVValid(false);
    }
  };

  const handleUploadFileClick = () => {
    // Abre a pasta para o upload de arquivo
    fileInputRef.current.click();
  };

  return (
    <>
      <PageTitle>Relatório de Peças BPO Total</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <label htmlFor='file-upload' style={{ display: 'block' }}>
            <Button
              fullWidth
              variant='outlined'
              style={{ backgroundColor: '#f50057', color: '#fff' }}
              onClick={handleUploadFileClick}
            >
              Importar arquivo
            </Button>
          </label>
          <input
            id='file-upload'
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            onClick={handleDownloadReport}
            fullWidth
            loading={isLoading}
            disabled={!isCSVValid || isLoading}
          >
            Exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
