import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

export const ImeiQuery = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    setIsLoading(true);
    window.location.href = 'https://www.consultaserialaparelho.com.br/public-web/welcome';
  };
  return (
    <>
      <PageTitle>Consulta IMEI</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <Button fullWidth loading={isLoading} onClick={handleButtonClick}>
            Consultar IMEI
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
