/* eslint-disable import-helpers/order-imports */
import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as Trash } from 'assets/images/updateService.svg';
import { Button, CustomAutocomplete, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService, xpcellService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const UpdateServiceGSPN = () => {
  const classes = useStyles();

  const [getStatusGspn, , loadingStatusGspn, refetchStatusGspn] = useQuery(
    () => xpcellService.getStatusGspn(),
    [],
  );

  const [getSubStatusGspn, , loadingSubStatusGspn, refetchSubStatusGspn] = useQuery(
    () => xpcellService.getSubStatusGspn(),
    [],
  );

  const statusGspnOptions = (getStatusGspn || []).map((status) => {
    return {
      value: status.sigla,
      label: status.sigla + ' - ' + status.descricao,
    };
  });

  const subStatusGspnOptions = (getSubStatusGspn || []).map((status) => {
    return {
      value: status.sigla,
      label: status.sigla + ' - ' + status.descricao,
    };
  });

  subStatusGspnOptions.push({ value: '', label: 'Vazio' });

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    toast.info('Atualizando a ordem de serviço no GSPN...');
    setSubmitting(true);
    try {
      values.reason = values.reason?.value;
      values.status = values.status?.value;
      await providersService.updatedStatusGspn(values);

      toast.success('Ordem de serviço atualizada com sucesso.');

      resetForm();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao atualizar o status no GSPN');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Atualizar status no GSPN</PageTitle>
      <Formik
        initialValues={{ service_order: '', status: '', reason: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='service_order'
                    size='small'
                    label='Service'
                    inputProps={{ maxLength: 50, minlength: 1 }}
                    component={Text}
                    onInput={toTrim}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Field
                    variant='outlined'
                    label='Status'
                    name='status'
                    size='small'
                    options={statusGspnOptions}
                    component={CustomAutocomplete}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Field
                    variant='outlined'
                    label='Reason'
                    name='reason'
                    size='small'
                    options={subStatusGspnOptions}
                    component={CustomAutocomplete}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <Button
                    fullWidth
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    loading={props.isSubmitting}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Grid className={classes.containerImg} container>
        <Trash className={classes.trashImage} />
      </Grid>
    </>
  );
};
