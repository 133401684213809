import { useRouteMatch } from 'react-router';

import { OrderProduct } from 'pages/Private/Collaborators/OrderProduct';

import { Route } from '../Route';

export const CollaboratorsRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/order-product`} component={OrderProduct} />
    </>
  );
};
