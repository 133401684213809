import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const filter = [
  { label: 'DT.Minuta', value: '0' },
  { label: 'PN Trocado', value: '1' },
  { label: 'Desc Componente Lançado', value: '2' },
  { label: 'Item HF', value: '3' },
  { label: 'Quantidade Peça', value: '4' },
  { label: 'Nivel Reparo', value: '5' },
  { label: 'Código de Sintoma', value: '6' },
  { label: 'NR Tec', value: '7' },
  { label: 'Nome', value: '8' },
  { label: 'Nome Tec Laudo', value: '9' },
  { label: 'Numero Tec Laudo', value: '10' },
  { label: 'Nome Cliente', value: '11' },
  { label: 'Sub.Categoria', value: '12' },
  { label: 'Peso', value: '13' },
];

export const PartsConsumption = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providersService.getPartsConsumption({
        type_filter: values.type_filter,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { PartsConsumption: worksheet },
          SheetNames: ['PartsConsumption'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Consumo_Peças_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Consumo de Peças</PageTitle>

      <Formik
        initialValues={{
          type_filter: [],
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleDownloadReport}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_filter'
                label='Produto'
                variant='outlined'
                options={filter}
                component={Select}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
