import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogHide = ({
  openDialogHide,
  handleCloseDialogHide,
  handleConfirmDialogHide,
  hideResend,
}) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const hideClient = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await providersService.hideClientRusubissionCasesOpen({
        idHide: hideResend.idHide,
        usuario: user.name,
      });
      toast.success('Oculto com Sucesso');
    } catch (error) {
      toast.error('Erro ao ocultar');
    } finally {
      setIsLoading(false);
      handleCloseDialogHide();
    }
  };

  return (
    <Dialog open={openDialogHide}>
      <Dialog.Title onClose={handleCloseDialogHide}>Ocultar</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            idHide: hideResend.idHide,
            usuario: user.name,
          }}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography variant='h5' align='center' style={{ paddingBottom: '2rem' }}>
                    Deseja realmente ocultar o reenvio do SAC?
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    //onClick={handleConfirmDialogHide}
                    loading={isLoading}
                    onClick={() => hideClient(props)}
                  >
                    Sim
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' onClick={handleCloseDialogHide}>
                    Não
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
