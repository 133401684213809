import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, FormatDate, FormatMoney, Spacer, Table } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { xpcellService } from 'services';

const columns = [
  { label: 'OS Xpcell', align: 'center' },
  { label: 'OS GSPN', align: 'center' },
  { label: 'Serial', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'CPF', align: 'center' },
  { label: 'Etapa', align: 'center' },
  { label: 'Processo', align: 'center' },
  { label: 'Atendimento', align: 'center' },
  { label: 'NF de serviço', align: 'center' },
  { label: 'NF de venda', align: 'center' },
  { label: 'Valor', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Total', align: 'center' },
  { label: 'Previsão de pagamento', align: 'center' },
  { label: 'Observação', align: 'center' },
  { label: 'Erro', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export const FinancialCloseTable = ({
  data,
  closingType,
  setLoading,
  loading,
  reset,
  setResults,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [getTotal, setTotal] = useState(0);
  const [getAvgValue, setAvgValue] = useState(0);
  const [itemsWithError, setItemsWithError] = useState(new Set());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const totalPrice = data.reduce(
      (acc, item) => acc + (Number(item?.value ?? 0) * Number(item?.amount ?? 0) ?? 0),
      0,
    );

    const totalAmount = data.reduce((acc, item) => acc + (Number(item?.amount ?? 0) ?? 0), 0);

    const avg = totalPrice / totalAmount ?? 1;

    setTotal(totalPrice);
    setAvgValue(avg);
  }, [data]);

  useEffect(() => {
    const errorIndices = new Set();
    data.forEach((item, index) => {
      if (item?.error !== undefined) {
        errorIndices.add(index);
      }
    });
    setItemsWithError(errorIndices);
  }, [data]);

  const handleRemoveItem = (index) => {
    const newData = data.filter((_, idx) => idx !== index);
    setResults(newData);
  };

  const handleKeepItem = (index) => {
    const newData = data.map((item, idx) => {
      if (idx === index) {
        return { ...item, error: undefined };
      }
      return item;
    });
    setResults(newData);

    setItemsWithError((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });
  };

  const onSubmit = async () => {
    if (itemsWithError.size > 0) {
      toast.error(
        'Existem erros nos itens. Por favor, resolva-os antes de confirmar o fechamento.',
      );
      return;
    }

    toast.info('Salvando o fechamento...');
    setLoading.toTrue();
    try {
      await xpcellService.createFinancialClosing({
        closing_types: closingType,
        closing_items: data,
      });
      toast.success('Fechamento salvo com sucesso.');
      reset();
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Erro ao salvar o fechamento!');
    } finally {
      setLoading.toFalse();
    }
  };

  return (
    <>
      <Spacer size={40} />
      <Paper elevation={2}>
        <PageTitle>Resultados</PageTitle>

        <Table
          loading={loading}
          headers={columns}
          striped
          emptyMessage='Nenhum registro encontrado.'
          data={data}
          disableNumeration
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {data?.map((item, key) => {
            const hasError = item?.error !== undefined;
            return (
              <TableRow key={key} style={{ backgroundColor: hasError ? 'red' : 'inherit' }}>
                <TableCell align='center'>{item?.reference}</TableCell>
                <TableCell align='center'>{item?.osParceiro}</TableCell>
                <TableCell align='center'>{item?.serial}</TableCell>
                <TableCell align='center'>{item?.cliente}</TableCell>
                <TableCell align='center'>{item?.cpf}</TableCell>
                <TableCell align='center'>{item?.etapa}</TableCell>
                <TableCell align='center'>{item?.processo}</TableCell>
                <TableCell align='center'>{item?.atendimento}</TableCell>
                <TableCell align='center'>{item?.nf_service}</TableCell>
                <TableCell align='center'>{item?.nf_seller}</TableCell>
                <TableCell align='center'>
                  <FormatMoney>{item?.value}</FormatMoney>
                </TableCell>
                <TableCell align='center'>{item?.amount}</TableCell>
                <TableCell align='center'>
                  <FormatMoney>{(item?.value ?? 0) * (item?.amount ?? 0)}</FormatMoney>
                </TableCell>
                <TableCell align='center'>
                  <FormatDate>{item?.payment_prevision}</FormatDate>
                </TableCell>
                <TableCell align='center'>{item?.observation}</TableCell>
                <TableCell align='center' style={{ color: hasError ? 'red' : 'inherit' }}>
                  {hasError ? item?.error : '---'}
                </TableCell>
                <TableCell align='center'>
                  {hasError ? (
                    <>
                      <IconButton onClick={() => handleKeepItem(key)}>
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      </IconButton>
                      <IconButton onClick={() => handleRemoveItem(key)}>
                        <CancelIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </>
                  ) : (
                    '---'
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </Paper>
      <Spacer size={30} />
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={12} lg={12}>
          <Typography style={{ color: 'blue', textAlign: 'end' }}>
            Quantidade de items: {data?.length}
          </Typography>
          <Typography style={{ color: 'red', textAlign: 'end' }}>
            Quantidade de erros: {itemsWithError.size}
          </Typography>
          <Typography style={{ color: 'purple', textAlign: 'end' }}>
            Ticket médio: <FormatMoney>{getAvgValue ?? 0}</FormatMoney>
          </Typography>
          <Typography style={{ color: 'green', textAlign: 'end' }}>
            Valor total: <FormatMoney>{getTotal ?? 0}</FormatMoney>
          </Typography>
        </Grid>
      </Grid>
      <Spacer size={25} />
      <Grid container justifyContent='space-between'>
        <Grid></Grid>
        <Grid>
          <Button
            fullWidth
            color='secondary'
            loading={loading}
            onClick={() => onSubmit()}
            disabled={itemsWithError.size > 0}
          >
            Confirmar fechamento
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
