import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TextField from '@mui/material/TextField';
import { Button, Table } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { DialogEdit } from './Dialog/DialogEdit';
import { DialogHide } from './Dialog/DialogHide';
import { DialogObs } from './Dialog/DialogObs';
import { DialogReopen } from './Dialog/DialogReopen';

const columns = [
  { label: 'ID' },
  { label: 'Num.OS' },
  { label: 'OS.Parceiro' },
  { label: 'Modelo' },
  { label: 'Serial' },
  { label: 'numSerie' },
  { label: 'Data' },
  { label: 'Objeto' },
  { label: 'CR' },
  { label: 'CPF' },
  { label: 'Telefone' },
  { label: 'Telefone2' },
  { label: 'Nome' },
  { label: 'Motivo' },
  { label: 'Obs' },
  { label: 'Editar' },
  { label: 'Ocultar' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const ResubmissionCasesOpen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState('');
  const [infoClient, setInfoClient] = useState([]);
  const [obsClient, setObsClient] = useState([]);
  const [hideResend, setHideResend] = useState([]);
  const [reopenHide, setReopenHide] = useState([]);
  const [openDialogObs, setDialogObs] = useState(false);
  const [openDialogEdit, setDialogEdit] = useState(false);
  const [openDialogHide, setDialogHide] = useState(false);
  const [openDialogReopen, setOpenDialogReopen] = useState(false);
  const [isDialogHideConfirmed, setIsDialogHideConfirmed] = useState(false);
  const [isDialogReopen, setisDialogReopenConfirmed] = useState(false);

  const dataTable = async () => {
    try {
      const response = await providersService.getResubmissionCasesOpen();
      const formattedData = response.data.map((item) => ({
        ...item,
        dtCadastro: formatDate(item.dtCadastro), // Aplicando formatação aqui
      }));
      setData(formattedData);
    } catch (error) {
      console.log('Erro ao buscar dados:', error);
    }
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getResubmissionCasesOpen();

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { ResubmissionCasesOpen: worksheet },
          SheetNames: ['ResubmissionCasesOpen'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Casos_Reenvio_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dataTable();
  }, []);

  const filteredData = useMemo(() => {
    const lowercasedFilter = filterText.toLowerCase();
    return filterText
      ? data.filter((item) => {
          return (
            (item.idTicket && item.idTicket.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.numeroOs && item.numeroOs.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.osParceiro &&
              item.osParceiro.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.Modelo && item.Modelo.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.serial && item.serial.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.numSerie && item.numSerie.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.objColetado &&
              item.objColetado.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.CR && item.CR.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.cpf && item.cpf.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.FONE && item.FONE.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.fone2 && item.fone2.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.clienteNome &&
              item.clienteNome.toString().toLowerCase().includes(lowercasedFilter)) ||
            (item.motivo && item.motivo.toString().toLowerCase().includes(lowercasedFilter))
          );
        })
      : data;
  }, [data, filterText]);

  const currentPageData = useMemo(() => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialogObs = (item) => {
    setDialogObs(true);
    setObsClient(item);
  };

  const handleCloseDialogObs = () => {
    setDialogObs(false);
  };

  const handleOpenDialogEdit = (item) => {
    setDialogEdit(true);
    setInfoClient(item);
  };

  const handleCloseDialogEdit = () => {
    setDialogEdit(false);
  };

  const handleOpenDialogHide = (item) => {
    setDialogHide(true);
    setHideResend(item);
  };

  const handleCloseDialogHide = () => {
    setDialogHide(false);
  };

  const handleOpenDialogReopen = (item) => {
    setOpenDialogReopen(true);
    setReopenHide(item);
  };

  const handleCloseDialogReopen = () => {
    setOpenDialogReopen(false);
  };

  const handleConfirmDialogHide = () => {
    setIsDialogHideConfirmed(true);
    handleCloseDialogHide(); // Fechar o modal após a confirmação, se necessário
  };

  const handleConfirmDialogReopen = () => {
    setisDialogReopenConfirmed(true);
    handleCloseDialogReopen();
  };

  return (
    <>
      <DialogObs
        openDialogObs={openDialogObs}
        handleCloseDialogObs={handleCloseDialogObs}
        obsClient={obsClient}
      />
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        infoClient={infoClient}
      ></DialogEdit>
      <DialogHide
        openDialogHide={openDialogHide}
        handleCloseDialogHide={handleCloseDialogHide}
        handleConfirmDialogHide={handleConfirmDialogHide}
        hideResend={hideResend}
      ></DialogHide>
      <DialogReopen
        openDialogReopen={openDialogReopen}
        handleCloseDialogReopen={handleCloseDialogReopen}
        handleConfirmDialogReopen={handleConfirmDialogReopen}
        reopenHide={reopenHide}
      ></DialogReopen>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        style={{ paddingBottom: '2rem' }}
      >
        <PageTitle>Casos de reenvio - Aberto</PageTitle>
        <Grid
          item
          xs={6}
          lg={4}
          container
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={6}>
            <Button fullWidth onClick={handleDownloadReport} loading={isLoading}>
              Exportar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              variant='outlined'
              label='Pesquisar'
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12}>
          <Table name='Table' headers={columns} maxHeight disableNumeration>
            {currentPageData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.idTicket}</TableCell>
                <TableCell>{item.numeroOs}</TableCell>
                <TableCell>{item.osParceiro}</TableCell>
                <TableCell>{item.Modelo}</TableCell>
                <TableCell>{item.serial}</TableCell>
                <TableCell>{item.numSerie}</TableCell>
                <TableCell>{item.dtCadastro}</TableCell>
                <TableCell>{item.objColetado}</TableCell>
                <TableCell>{item.CR}</TableCell>
                <TableCell>{item.cpf}</TableCell>
                <TableCell>{item.FONE}</TableCell>
                <TableCell>{item.fone2}</TableCell>
                <TableCell>{item.clienteNome}</TableCell>
                <TableCell>{item.motivo}</TableCell>
                <TableCell>
                  <RemoveRedEyeIcon
                    onClick={() => handleOpenDialogObs(item)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell>
                  <EditIcon
                    style={{ cursor: 'pointer', color: '#512da8' }}
                    onClick={() => handleOpenDialogEdit(item)}
                  />
                </TableCell>
                <TableCell>
                  {item.hide === true || null ? (
                    <CheckCircleIcon
                      color='secondary'
                      style={{ cursor: 'pointer', color: '#f50057' }}
                      onClick={() => handleOpenDialogReopen(item)}
                    />
                  ) : (
                    <CancelIcon
                      color='secondary'
                      style={{ cursor: 'pointer', color: '#f50057' }}
                      onClick={() => handleOpenDialogHide(item)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};
