import { api } from 'services/api';
export class ReleasesService {
  async getBlockOs(data) {
    return api.post(`/xpcell-releases/block-os`, data);
  }
  async getTypeBlock() {
    return api.post(`/xpcell-releases/getTypeBlock`);
  }

  async getNewUnlock(data) {
    return api.post(`/xpcell-releases/getNewUnlock`, data);
  }

  async getNewMsgBlock(data) {
    return api.post(`/xpcell-releases/getNewMsgBlock`, data);
  }

  async getValueBlock(data) {
    return api.post(`/xpcell-releases/getValueBlock`, data);
  }

  async insertBlock(data) {
    return api.post(`/xpcell-releases/insertBlock`, data);
  }
}

export const releasesService = new ReleasesService();
