import { useRouteMatch } from 'react-router';

import { DeletePartsGspn } from 'pages/Private/Gspn/DeletePartsGspn';
import { UpdateServiceGSPN } from 'pages/Private/Gspn/UpdateServiceGspn';

import { Route } from '../Route';

export const GspnRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/change-status-gspn`} component={UpdateServiceGSPN} />
      <Route isPrivate path={`${url}/delete-parts-gspn`} component={DeletePartsGspn} />
    </>
  );
};
