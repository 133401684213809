import { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';

import CheckIcon from '@mui/icons-material/Check';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Button, Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { ReceivingProductsTable } from './components/ReceivingProductsTable';
import { useStyles } from './styles';

export const ReceivingProducts = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <PageTitle>Recebimento de produtos</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Button fullWidth endIcon={<DownloadingIcon fontSize='large' />}>
            Importar XML
          </Button>
        </Grid>
      </Grid>
      <ReceivingProductsTable />
      <Spacer size={15} />
      <Grid container justifyContent='flex-end'>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Button fullWidth endIcon={<CheckIcon fontSize='large' />} color='secondary'>
            Confirmar recebimento
          </Button>
        </Grid>
      </Grid>
      {/* <Grid className={classes.containerImg} container>
        <ReceivedImage className={classes.receivedImage} />
      </Grid> */}
    </Fragment>
  );
};
