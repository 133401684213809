/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { getFieldError } from 'helpers/formik';
import useMask from 'hooks/useMask';

const TextComponent = forwardRef(
  (
    {
      field,
      form,
      fullWidth,
      name,
      value,
      errorMessageProp,
      disabled,
      loading,
      helperText,
      startAdornment,
      endAdornment,
      onChange,
      onBlur,
      mask,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    name = field?.name ?? name;
    value = field?.value ?? value;

    const { maskedValue, maskClean } = useMask(mask, value);

    const onChangeHandler = useCallback(
      (e) => {
        form && form.setFieldValue(field.name, maskClean(e.target.value));
        onChange && onChange(e);
      },
      [form, field, maskClean, onChange],
    );

    const onBlurHandler = useCallback(
      (e) => {
        const v = e.target.value;

        form?.setFieldTouched(field.name, true);

        if (!v) return;

        form?.setFieldValue(field.name, maskClean(e.target.value));

        onBlur && onBlur(e);
      },
      [form, field, maskClean, onBlur],
    );

    const onSetDefault = useCallback(() => {
      if (defaultValue) {
        value = 1;
      }
    }, []);

    const InputProps = React.useMemo(() => {
      let end = null;
      let start = null;

      if (startAdornment) {
        start = <InputAdornment position='start'>{startAdornment}</InputAdornment>;
      }

      if (endAdornment) {
        end = <InputAdornment position='end'>{endAdornment}</InputAdornment>;
      }

      if (loading) {
        end = (
          <InputAdornment position='end'>
            <CircularProgress color='secondary' size={20} />
          </InputAdornment>
        );
      }

      return {
        endAdornment: end,
        startAdornment: start,
      };
    }, [loading, endAdornment, startAdornment]);

    const errorMessage = errorMessageProp || (form && getFieldError(field?.name, form));
    const hasError = !!errorMessage;

    return (
      <TextField
        classes={props.classes}
        fullWidth={fullWidth ?? true}
        helperText={errorMessage ?? helperText}
        disabled={form?.isSubmitting || disabled || loading}
        error={hasError}
        name={name}
        value={maskedValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        InputProps={InputProps}
        inputRef={ref}
        {...props}
      />
    );
  },
);

TextComponent.displayName = 'Text';

export const Text = React.memo(TextComponent);
