import { useRouteMatch } from 'react-router';

import { BudgetSacGraphic } from 'pages/Private/SAC/BudgetSacGraphic';

import { Route } from '../Route';

export const SacRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/graphic/budget`} component={BudgetSacGraphic} />
    </>
  );
};
