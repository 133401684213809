import { useRouteMatch } from 'react-router';

import { ProductConference } from 'pages/Private/Receivement/ProductConference';
import { ReceivingProducts } from 'pages/Private/Receivement/ReceivingProducts';

import { Route } from '../Route';

export const ReceivementRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/products`} component={ReceivingProducts} />
      <Route isPrivate path={`${path}/prodtucts/conference`} component={ProductConference} />
    </>
  );
};
