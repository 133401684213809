import { useCallback, useEffect, useState } from 'react';

import { BudgetApproved } from './BudgetApproved';
import { BudgetMonth } from './BudgetMonth';

export const BudgetSacGraphic = () => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeStep === 0) {
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }, 70000);

    return () => {
      clearInterval(interval);
    };
  }, [activeStep]);

  const getStepContent = useCallback((activeStep) => {
    const steps = {
      0: <BudgetApproved />,
      1: <BudgetMonth />,
    };

    return steps[activeStep];
  }, []);

  return <>{getStepContent(activeStep)}</>;
};
