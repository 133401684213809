import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const selectEntrega = [
  { label: 'Em processo', value: '0' },
  { label: 'Entregues', value: '1' },
];

export const DeliveryConfirmation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getDeliveryConfirmation({
        input_entrega: values.input_entrega,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { DeliveryConfirmation: worksheet },
          SheetNames: ['DeliveryConfirmation'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Confirmação_Entrega_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório Confirmação de Entrega</PageTitle>

      <Formik
        initialValues={{ input_data: new Date(), output_data: new Date() }}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_entrega'
                  label='Selecione'
                  variant='outlined'
                  options={selectEntrega}
                  component={Select}
                />
              </Grid>
              {props.values.input_entrega === '1' && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
