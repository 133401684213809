import { useRouteMatch } from 'react-router';

import { BlockOs } from 'pages/Private/Releases/BlockOs';

import { Route } from '../Route';

export const ReleasesRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/block-os`} component={BlockOs} />
    </>
  );
};
